import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../loading/loading.store";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";

import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";

const service = new Service();

export const LOGIN_USER_OK = "LOGIN_USER_OK";
export const LOGIN_USER_ERR = "LOGIN_USER_ERR";
export const GET_USER_DETAIL_OK = "GET_USER_DETAIL_OK";
export const GET_USER_DETAIL_ERR = "GET_USER_DETAIL_ERR";
export const LOGIN_USER_OUT = "LOGIN_USER_OUT";

export function loginUserOk(payload) {
  return {
    type: LOGIN_USER_OK,
    payload,
  };
}

export function loginUserErr(payload) {
  return {
    type: LOGIN_USER_ERR,
    payload,
  };
}

export function getUserDetailOk(payload) {
  return {
    type: GET_USER_DETAIL_OK,
    payload,
  };
}

export function getUserDetailErr(payload) {
  return {
    type: GET_USER_DETAIL_ERR,
    payload,
  };
}

function loginOut() {
  return { type: LOGIN_USER_OUT };
}

export const userService = {
  login,
  logout,
  // GetUserAccountDetail,
};

function login(params) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.LoginUser, params)
      .then((response) => {
        dispatch(loginUserOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            token: response.content.token,
            email: params.email,
            role: "user",
          })
        );
        // setCookies("token", response.content.token, {
        //   path: response.content.returnUrl,
        // });
        var returnUrl = sessionStorage.getItem("returnUrl");
        //Remove return url when don't login
        sessionStorage.setItem("returnUrl", "");
        if (returnUrl && returnUrl !== "") {
          history.push(returnUrl);
        } else {
          history.push(UrlCollect.HomeUser);
        }
      })
      .catch((error) => {
        dispatch(loginUserErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}

function logout() {
  // remove user from local storage to log user out
  return function (dispatch) {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userDetail");
    sessionStorage.removeItem("linkService");
    dispatch(loginOut());
    history.push(UrlCollect.StartPage);
  };

  /*
  // Save data to sessionStorage
  sessionStorage.setItem('key', 'value');
  // Get saved data from sessionStorage
  let data = sessionStorage.getItem('key');
  */
}

// export function GetUserAccountDetail() {
//   return function (dispatch) {
//     dispatch(AddOneSlotToLoadingQueue);

//     return service
//       .get(ApiUrl.GetUserAccountDetail)
//       .then((response) => {
//         dispatch(getUserDetailOk(response));
//         dispatch(RemoveOneSlotToLoadingQueue);
//       })
//       .catch((error) => {
//         dispatch(getUserDetailErr(error));
//         dispatch(RemoveOneSlotToLoadingQueue);
//         ShowNotification(error.errorMessage, NotificationMessageType.Error);
//       });
//   };
// }
