import UrlCollect from "./common/url-collect";

//--- Layout
import LayoutDesktopView from "./components/layout/layout.view.jsx";
import LayoutDesktopStartView from "./components/layout/layout-start.view.jsx";
import LayoutUserAdminView from "./components/layout/layout-user-admin.view";

//--- Homepage

import StartPage from "./modules/start-page/start-page";

//-- User
import RegisterDesktop from "./modules/user/register/register.view.jsx";
import HomeUser from "./modules/home-user/home-user.view.jsx";
import PlanningDesign from "./modules/user/planting-design/planning-design.view";
import ListQualify from "./modules/user/qualify/list-qualify.view";
import QualifyDetail from "./modules/user/qualify/qualify-detail.view";
import ListHelping from "./modules/user/helping/list-helping.view";
import Helping from "./modules/user/helping/helping.view";
import UserAdmin from "./modules/user/user-admin/user.admin.view";
import ListNotification from "./modules/user/notification/list-notification.view";
import NotificationDetail from "./modules/user/notification/notification-detail.view";
// import FileDetailRedirect from "./modules/user/file-detail/file-detail-redirect.view";
import ForgotPassword from "./modules/user/forgot-password/forgot-password.view";
import FileDetail from "./modules/user/file-detail/file-detail.view";
import FileResult from "./modules/user/file-result/file-result.view";
import Page404 from "./modules/user/page-404/page-404.view";

//--- Error pages

//--- Admin
// import LoginAdm from "./modules/admin/login/login";
// import DashboardAdmin from "./modules/admin/dashboard/dashboard-admin";
// import DocumentNew from "./modules/admin/document-new/document-new";
// import DocumentNewDetail from "./modules/admin/document-new-detail/document-new-detail";
// import Weblink from "./modules/admin/weblink/weblink";
// import Province from "./modules/admin/province/province";
// import District from "./modules/admin/district/district";
// import Commune from "./modules/admin/commune/commune";
// import Project from "./modules/admin/project/project";
// import Department from "./modules/admin/department/department";
// import Position from "./modules/admin/position/position";
// import News from "./modules/admin/news/news";

const rolesAdmin = ["admin", "manage"];
const rolesUser = ["user", "staff"];

var routers = [
  {
    path: UrlCollect.StartPage,
    component: StartPage,
    layout: LayoutDesktopStartView,
    isAdmin: false,
  },
  {
    path: UrlCollect.Register,
    component: RegisterDesktop,
    layout: LayoutDesktopStartView,
    isAdmin: false,
  },
  {
    path: UrlCollect.HomeUser,
    component: HomeUser,
    layout: LayoutDesktopView,
    roles: rolesUser,
    isAdmin: false,
  },
  {
    path: UrlCollect.PlanningDesign + "/:id",
    component: PlanningDesign,
    layout: LayoutDesktopView,
    roles: rolesUser,
    isAdmin: false,
  },
  {
    path: UrlCollect.PlanningDesign + "/:id/:fileId",
    component: PlanningDesign,
    layout: LayoutDesktopView,
    roles: rolesUser,
    isAdmin: false,
  },
  // {
  //   path: UrlCollect.ListFiles + "/:status",
  //   component: ListFiles,
  //   layout: LayoutUserAdminView,
  //   roles: rolesUser,
  //   isAdmin: false,
  // },
  // {
  //   path: UrlCollect.FileDetail + "/:id",
  //   component: FileDetailRedirect,
  //   layout: LayoutUserAdminView,
  //   roles: rolesUser,
  //   isAdmin: false,
  // },
  {
    path: UrlCollect.FileDetail + "/:id",
    component: FileDetail,
    layout: LayoutUserAdminView,
    roles: rolesUser,
    isAdmin: false,
  },
  {
    path: UrlCollect.FileResult + "/:id",
    component: FileResult,
    layout: LayoutUserAdminView,
    roles: rolesUser,
    isAdmin: false,
  },
  // {
  //   path: UrlCollect.ViewUser,
  //   component: updateUser,
  //   layout: LayoutUserAdminView,
  //   roles: rolesUser,
  //   isAdmin: false,
  // },
  // {
  //   path: UrlCollect.ChangePassword,
  //   component: ChangePassword,
  //   layout: LayoutUserAdminView,
  //   roles: rolesUser,
  //   isAdmin: false,
  // },
  {
    path: UrlCollect.ListQualify,
    component: ListQualify,
    layout: LayoutDesktopView,
    roles: rolesUser,
    isAdmin: false,
  },
  {
    path: UrlCollect.QualifyDetail + "/:id",
    component: QualifyDetail,
    layout: LayoutDesktopView,
    roles: rolesUser,
    isAdmin: false,
  },
  {
    path: UrlCollect.ListNotification,
    component: ListNotification,
    layout: LayoutDesktopView,
    roles: rolesUser,
    isAdmin: false,
  },
  {
    path: UrlCollect.NotificationDetail + "/:id",
    component: NotificationDetail,
    layout: LayoutDesktopView,
    roles: rolesUser,
    isAdmin: false,
  },
  {
    path: UrlCollect.ListHelping,
    component: ListHelping,
    layout: LayoutDesktopView,
    roles: rolesUser,
    isAdmin: false,
  },
  {
    path: UrlCollect.ForgotPassword,
    component: ForgotPassword,
    layout: LayoutDesktopStartView,
    isAdmin: false,
  },
  {
    path: UrlCollect.Helping + "/:id",
    component: Helping,
    layout: LayoutDesktopView,
    roles: rolesUser,
    isAdmin: false,
  },
  {
    path: UrlCollect.UserAdmin + ":view",
    component: UserAdmin,
    layout: LayoutUserAdminView,
    roles: rolesUser,
    isAdmin: false,
  },

  //Admin ===============================================================
  // {
  //   path: UrlCollect.AdmLogin,
  //   component: LoginAdm,
  //   layout: LayoutAdminLoginView,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.DashboardAdmin,
  //   component: DashboardAdmin,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.DocumentNew,
  //   component: DocumentNew,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.DocumentNewDetail,
  //   component: DocumentNewDetail,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.Weblink,
  //   component: Weblink,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.Province,
  //   component: Province,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.District,
  //   component: District,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.Commune,
  //   component: Commune,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.Project,
  //   component: Project,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.Department,
  //   component: Department,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.Position,
  //   component: Position,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  // {
  //   path: UrlCollect.News,
  //   component: News,
  //   layout: LayoutAdminView,
  //   roles: rolesAdmin,
  //   isAdmin: true,
  // },
  {
    path: UrlCollect.Page404,
    component: Page404,
    layout: LayoutUserAdminView,
    roles: rolesUser,
    isAdmin: false,
  },
  { redirect: true, path: "/", pathTo: "/" },
];
export default routers;
