import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const NOTIFICATION_GET_DETAIL_OK = "NOTIFICATION_GET_DETAIL_OK";
export const NOTIFICATION_GET_DETAIL_ERR = "NOTIFICATION_GET_DETAIL_ERR";

export function notificationGetDetailOk(payload) {
  return {
    type: NOTIFICATION_GET_DETAIL_OK,
    payload,
  };
}

export function notificationGetDetailErr(payload) {
  return {
    type: NOTIFICATION_GET_DETAIL_ERR,
    payload,
  };
}

export const notificationGetDetailService = {
  getDetail,
};

function getDetail(id) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.NotificationGetDetail + id)
      .then((response) => {
        dispatch(notificationGetDetailOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(notificationGetDetailErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
