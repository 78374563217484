import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const QUALIFY_GET_LIST_ALL_OK = "QUALIFY_GET_LIST_ALL_OK";
export const QUALIFY_GET_LIST_ALL_ERR = "QUALIFY_GET_LIST_ALL_ERR";

export function qualifyGetListAllOk(payload) {
  return {
    type: QUALIFY_GET_LIST_ALL_OK,
    payload,
  };
}

export function qualifyGetListAllErr(payload) {
  return {
    type: QUALIFY_GET_LIST_ALL_ERR,
    payload,
  };
}

export const qualifyGetListAllService = {
  getListAll,
};

function getListAll(params) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.QualifyGetListAll, params)
      .then((response) => {
        dispatch(qualifyGetListAllOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(qualifyGetListAllErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
