import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const UPLOAD_FILE_OK = "UPLOAD_FILE_OK";
export const UPLOAD_FILE_ERR = "UPLOAD_FILE_ERR";

export function uploadFileOk(payload) {
  return {
    type: UPLOAD_FILE_OK,
    payload,
  };
}

export function uploadFileErr(payload) {
  return {
    type: UPLOAD_FILE_ERR,
    payload,
  };
}

export const uploadFileService = {
  uploadFile,
};

function uploadFile(params) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.EvaluateRecordsType_UploadFile, params)
      .then((response) => {
        dispatch(uploadFileOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(uploadFileErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
