/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Breadcrumb from "react-bootstrap/Breadcrumb";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import UrlCollect from "../../../common/url-collect";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { notificationGetListAllService } from "../../../redux/store/user/notification/get-list-all.action";
// import NotificationDetail from "../notification/notification-detail.view";
import DateFormat from "dateformat";
import history from "../../../common/history";

const pagination = paginationFactory({
  sizePerPage: 10,
  paginationSize: 5,
  //withFirstAndLast: false,
  alwaysShowAllBtns: true,
  //firstPageText: 'trang dau tien',
  hideSizePerPage: true,
  //showTotal: true,
});

class ListNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      isList: true,
    };
  }

  componentDidMount() {
    this.props.notificationGetlistAll({});
  }

  handleCallback = (childData) => {
    this.setState({ isList: childData });
  };

  render() {
    const columns = [
      {
        headerAttrs: {
          hidden: true,
        },
        dataField: "name",
        text: "Name",
      },
      {
        headerAttrs: {
          hidden: true,
        },
        dataField: "sentDate",
        text: "Date",
        formatter: (cellContent, row) => {
          return (
            <span className="t-a-right">
              {DateFormat(row.sentDate, "dd/mm/yyyy")}
            </span>
          );
        },
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.setState({ id: row.id, isList: false });
        history.push(UrlCollect.NotificationDetail + "/" + row.id);
      },
    };
    const rowClass = (row, rowIndex) => {
      if (row.isRead) {
        return "read wrap-text row-pointer";
      } else {
        return "not-read wrap-text row-pointer";
      }
    };

    const products = [];
    if (
      this.props.notificationGetListAllState.content &&
      this.props.notificationGetListAllState.content.items.length > 0
    ) {
      this.props.notificationGetListAllState.content.items.map(
        (item, index) => {
          products.push(item);
        }
      );
    }

    return (
      <section className="list-notification">
        <div className="wrapper-content">
          <Container>
            <Breadcrumb className="title">
              <Breadcrumb.Item
                href={UrlCollect.HomeUser}
                className="breadcrumb-icon-home"
              >
                <img
                  alt=""
                  src={require("../../../assets/icon/entypo_home.png")}
                ></img>{" "}
                Trang chủ
              </Breadcrumb.Item>
              <Breadcrumb.Item>Thông báo</Breadcrumb.Item>
            </Breadcrumb>
            <div className="body-content-list">
              {/* <span className={this.state.isList ? "" : "display-none"}> */}
              <BootstrapTable
                rowEvents={rowEvents}
                keyField="id"
                data={products}
                columns={columns}
                pagination={pagination}
                classes="table-striped"
                rowClasses={rowClass}
                hover
              />
              {/* </span>
              <span className={this.state.isList ? "display-none" : ""}>
                {this.state.isList ? (
                  ""
                ) : (
                  <NotificationDetail
                    id={this.state.id}
                    parentCallback={this.handleCallback}
                  />
                )}
              </span> */}
            </div>
          </Container>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  notificationGetListAllState: state.notificationGetListAll,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notificationGetlistAll: notificationGetListAllService.getListAll,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListNotification);
