import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../../loading/loading.store";

import Service from "../../../../../api/api-service";
import { ApiUrl } from "../../../../../api/api-url";
import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";

const service = new Service();

export const GET_LIST_LINK_SERVICE_OK = "GET_LIST_LINK_SERVICE_OK";
export const GET_LIST_LINK_SERVICE_ERR = "GET_LIST_LINK_SERVICE_ERR";

export function getListLinkServiceOk(payload) {
  return {
    type: GET_LIST_LINK_SERVICE_OK,
    payload,
  };
}

export function getListLinkServiceErr(payload) {
  return {
    type: GET_LIST_LINK_SERVICE_ERR,
    payload,
  };
}

export const getListLinkServiceAction = {
  getListLinkService,
};

function getListLinkService() {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetListLinkService)
      .then((response) => {
        dispatch(getListLinkServiceOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getListLinkServiceErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
