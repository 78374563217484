import { DELETE_FILE_OK, DELETE_FILE_ERR } from "./delete-file.action";

const InitState = {
  error: null,
  content: null,
};

export default function DeleteFileReducer(state = InitState, action) {
  switch (action.type) {
    case DELETE_FILE_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case DELETE_FILE_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
