import {
  QUALIFY_GET_DETAIL_OK,
  QUALIFY_GET_DETAIL_ERR,
} from "./get-detail.action";

const InitState = {
  error: null,
  content: null,
};

export default function QualifyGetDetailReducer(state = InitState, action) {
  switch (action.type) {
    case QUALIFY_GET_DETAIL_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case QUALIFY_GET_DETAIL_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
