import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../loading/loading.store";
//import history from "../../../../common/history";
//import UrlCollect from "../../../../common/url-collect";
import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
const service = new Service();

export const DISTRICT_OK = "DISTRICT_OK";
export const DISTRICT_ERR = "DISTRICT_ERR";

export function getDistrictOk(payload) {
  return { type: DISTRICT_OK, payload };
}

export function getDistrictErr(payload) {
  return { type: DISTRICT_ERR, payload };
}

export const districtService = {
  getDistrict,
};

function getDistrict(provinceId) {
  var params = "provinceId=" + provinceId;
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetLookupDistrict, params)
      .then((response) => {
        dispatch(getDistrictOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getDistrictErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
}
