/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Row from "react-bootstrap/Row";
import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
import { forgotPasswordService } from "../../../redux/store/user/forgot-password/forgot-password.action";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const initialState = {
  email: "",
};
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  backClick = (e) => {
    e.preventDefault();
    history.push(UrlCollect.StartPage);
  };

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    var params = {
      email: this.state.email,
    };
    this.props.forgotPassword(params).then((x) => {
      if (this.props.forgotPasswordState.content) {
        ShowNotification(
          "Đặt lại mật khẩu thành công. Kiểm tra email của bạn",
          NotificationMessageType.Success
        );
      }
    });
  };

  componentDidMount() {}

  render() {
    return (
      <section className="forgot-password">
        <Container>
          <div className="margin-body">
            <Breadcrumb className="title">Quên mật khẩu</Breadcrumb>
            <div className="body-content">
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col xs={3} md={2}></Col>
                  <Col xs={12} md={8}>
                    <Form.Row>
                      <Form.Group as={Col} className="mg-t-5 center">
                        <Form.Label>Email</Form.Label>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Control
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                          type="email"
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                  <Col xs={3} md={2}></Col>
                </Row>
                <Row>
                  <Col xs={3} md={2}></Col>
                  <Col xs={12} md={8} className="center">
                    <Button
                      onClick={this.backClick}
                      className="button-back w-120"
                      variant="primary"
                    >
                      HỦY
                    </Button>
                    <span className="margin-5"></span>
                    <Button
                      className="button-register"
                      variant="primary"
                      type="submit"
                    >
                      ĐẶT LẠI MẬT KHẨU
                    </Button>
                  </Col>
                  <Col xs={3} md={2}></Col>
                </Row>
                <Row></Row>
              </Form>
            </div>
          </div>
        </Container>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  forgotPasswordState: state.forgotPassword,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // SetStateSidebar: setStateSidebarUser,
      forgotPassword: forgotPasswordService.forgotPassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
