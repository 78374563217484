/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../page-404/page-404.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import UrlCollect from "../../../common/url-collect";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class Page404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="wrapper-content">
        <Breadcrumb className="title">
          <Breadcrumb.Item
            href={UrlCollect.HomeUser}
            className="breadcrumb-icon-home"
          >
            <img src={require("../../../assets/icon/entypo_home.png")}></img>{" "}
            Trang chủ
          </Breadcrumb.Item>
          <Breadcrumb.Item>Không tìm thấy trang</Breadcrumb.Item>
        </Breadcrumb>
        <div className="wrapper">
          <div className="page-404">
            <h2>404</h2>
            <p>Rất tiếc không tìm thấy trang phù hợp</p>
            <p>Trang không tồn tại hoặc đã bị xóa</p>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Page404);
