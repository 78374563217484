import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const CREATESTEP1_OK = "CREATESTEP1_OK";
export const CREATESTEP1_ERR = "CREATESTEP1_ERR";

export function createStep1Ok(payload) {
  return {
    type: CREATESTEP1_OK,
    payload,
  };
}

export function createStep1Err(payload) {
  return {
    type: CREATESTEP1_ERR,
    payload,
  };
}

export const createStep1Service = {
  createStep1,
};

function createStep1(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreateStep1, data)
      .then((response) => {
        dispatch(createStep1Ok(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(createStep1Err(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
