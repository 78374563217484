import React from "react";
import { Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import "./login-modal.scss";
import { userService } from "../../redux/store/user/acc-user.action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import UrlCollect from "../../common/url-collect";

const initialState = {
  email: "",
  password: "",
  rememberMe: false,
  returnUrl: "",
  showCapcha: false,
};

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
  }

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = React.useState("");
  // const [rememberMe, setRememberMe] = React.useState(false);
  // const [returnUrl, setReturnUrl] = React.useState("");
  componentDidMount() {
    loadCaptchaEnginge(3);
    var tag = document.getElementById("reload_href");
    tag.parentElement.classList.add("reload-capcha");
    tag.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise color-black" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/><path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/></svg>';
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  onLoginClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var param = {
        email: this.state.email,
        password: this.state.password,
        rememberMe: this.state.rememberMe,
        returnUrl: this.state.returnUrl,
      };
      this.props.login(param);
    }
  };

  validateForm() {
    var item;
    if (this.state.email === "") {
      item = document.getElementById("email");
      item.focus();
      ShowNotification(
        "Tên đăng nhập không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.email !== "") {
      const re = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
      if (!re.test(this.state.email)) {
        item = document.getElementById("email");
        item.focus();
        ShowNotification("Email không hợp lệ", NotificationMessageType.Error);
        return false;
      }
    }
    if (this.state.password === "") {
      item = document.getElementById("password");
      item.focus();
      ShowNotification(
        "Mật khẩu không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    let user_captcha_value = document.getElementById("user_captcha_input");

    if (validateCaptcha(user_captcha_value.value, false) === false) {
      user_captcha_value.focus();
      ShowNotification("Nhập mã capcha sai", NotificationMessageType.Error);
      return false;
    }
    return true;
  }

  render() {
    return (
      <Modal
        {...this.props}
        centered
        dialogClassName="modal-login"
        className="dialog-login"
      >
        <Modal.Body>
          <Container>
            <Form noValidate onSubmit={this.onLoginClick}>
              <Row>
                <Col>
                  <div className="text-center color-login">
                    <h4>ĐĂNG NHẬP</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Tên Đăng nhập</Form.Label>
                    <Form.Control
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      required
                      type="email"
                      placeholder=""
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Mật Khẩu</Form.Label>
                    <Form.Control
                      id="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      required
                      type="password"
                      placeholder=""
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Nhập Mã Xác Thực</Form.Label>
                    <Form.Row>
                      <Col xs={6}>
                        <Form.Control
                          id="user_captcha_input"
                          name="user_captcha_input"
                          required
                          type="text"
                          placeholder=""
                        />
                      </Col>
                      <Col xs={6} className="capcha">
                        <LoadCanvasTemplate />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                  <Form.Group>
                    <Form.Check
                      name="rememberMe"
                      value={this.state.rememberMe}
                      onChange={(e) =>
                        this.setState({ rememberMe: e.target.checked })
                      }
                      label="Lưu mật khẩu"
                      type="checkbox"
                    />
                  </Form.Group>
                  <Button
                    id="btn-login"
                    size="lg"
                    block
                    variant="primary"
                    type="submit"
                    // onClick={(e) => onLoginClick(e)}
                  >
                    ĐĂNG NHẬP
                  </Button>
                </Col>
              </Row>
              <Row className="mg-top-40">
                <Col>
                  <div className="text-center text-body">
                    <a href={UrlCollect.ForgotPassword}>Quên Mật Khẩu?</a>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center text-body">
                    Chưa Có Tài Khoản? <a href="register">Đăng Kí</a>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: userService.login,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
