import { UPLOAD_FILE_OK, UPLOAD_FILE_ERR } from "./uploadFile.action";

const InitState = {
  errorMessage: "",
  errorType: "",
  err: null,
  content: null,
};

export default function UploadFileReducer(state = InitState, action) {
  switch (action.type) {
    case UPLOAD_FILE_OK: {
      return {
        ...state,
        err: null,
        content: action.payload.content,
      };
    }
    case UPLOAD_FILE_ERR: {
      return {
        ...state,
        errorType: action.payload.errorType,
        errorMessage: action.payload.errorMessage,
        content: null,
      };
    }
    default:
      return state;
  }
}
