import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const REGISTER_OK = "REGISTER_OK";
export const REGISTER_ERR = "REGISTER_ERR";

export function registerOk(payload) {
  return {
    type: REGISTER_OK,
    payload,
  };
}

export function registerErr(payload) {
  return {
    type: REGISTER_ERR,
    payload,
  };
}

export const registerService = {
  register,
};

function register(data) {
  var params = {
    email: data.emailUsername,
    fullName: data.fullName,
    password: data.password,
    confirmPassword: data.confirmPassword,
    identityId: data.identityId,
    identityDate: data.identityDate,
    identityBy: data.identityBy,
    sex: data.sex === "male" ? true : false,
    phoneNumber: data.phoneNumberUser,
    createCompany: {
      name: data.name,
      taxCode: data.taxCode,
      address: data.address,
      phoneNumber: data.phoneNumber,
      faxNumber: data.faxNumber,
      email: data.emailCompany,
      provinceId: data.provinceId,
      districtId: data.districtId,
      communeId: data.communeId > 0 ? data.communeId : null,
      certificateNumber: data.certificateNumber,
    },
  };
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.Register, params)
      .then((response) => {
        dispatch(registerOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(registerErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
