import { GET_LIST_ALL_OK, GET_LIST_ALL_ERR } from "./getListAll.action";

const InitState = {
  error: null,
  content: null,
};

export default function GetListAllReducer(state = InitState, action) {
  switch (action.type) {
    case GET_LIST_ALL_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_LIST_ALL_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
