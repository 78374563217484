/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import "../user.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { provinceService } from "../../../redux/store/province/province.service";
import { districtService } from "../../../redux/store/district/district.action";
import { communeService } from "../../../redux/store/commune/commune.action";
import Row from "react-bootstrap/Row";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import { Container } from "react-bootstrap";
import { getUserService } from "../../../redux/store/user/get-user/get-user.action";
import { updateUserService } from "../../../redux/store/user/update-user/update-user.action";

const initialState = {
  name: "",
  taxCode: "",
  address: "",
  phoneNumber: "",
  faxNumber: "",
  emailCompany: "",
  provinceId: 0,
  provinceObj: { id: 0, name: "" },
  districtId: 0,
  districtObj: { id: 0, name: "" },
  communeId: 0,
  communeObj: { id: 0, name: "" },
  // emailUsername: "",
  // password: "",
  // confirmPassword: "",
  certificateNumber: "",
  disableDistrict: true,
  disableCommune: true,
  fullName: "",
  identityId: "",
  identityDate: new Date(),
  identityBy: "",
  sex: "male",
  phoneNumberUser: "",
};

class UpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    // this.state = this.state = {
    //   name: "thanh@gmail.com",
    //   taxCode: "1111111111",
    //   address: "abc",
    //   phoneNumber: "0973999999",
    //   faxNumber: "0973999999",
    //   emailCompany: "com@gmail.com",
    //   provinceId: 0,
    //   provinceObj: { id: 0, name: "" },
    //   districtId: 0,
    //   districtObj: { id: 0, name: "" },
    //   communeId: 0,
    //   communeObj: { id: 0, name: "" },
    //   emailUsername: "user@gmail.com",
    //   password: "Fsoft@12345",
    //   confirmPassword: "Fsoft@12345",
    //   certificateNumber: "125352120",
    //   disableDistrict: true,
    //   disableCommune: true,
    //   fullName: "tran van thanh",

    //   identityId: "123123123",
    //   identityDate: new Date(),
    //   identityBy: "ha noi",
    //   sex: "male",
    // };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  onChangeNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.validateForm()) {
      var params = {
        id: this.props.getUser.content.id,
        fullName: this.state.fullName,
        identityId: this.state.identityId,
        identityDate: this.state.identityDate,
        identityBy: this.state.identityBy,
        sex: this.state.sex === "male" ? true : false,
        phoneNumber: this.state.phoneNumberUser,
        // address:this.state.address,
        company: {
          id: this.props.getUser.content.company.id,
          name: this.state.name,
          taxCode: this.state.taxCode,
          address: this.state.address,
          phoneNumber: this.state.phoneNumber,
          faxNumber: this.state.faxNumber,
          email: this.state.emailCompany,
          provinceId: this.state.provinceId,
          districtId: this.state.districtId,
          communeId: this.state.communeId ? this.state.communeId : null,
        },
      };
      this.props.updateUser(params).then((x) => {
        if (this.props.updateUserState.content) {
          ShowNotification(
            "Thay đổi thành công",
            NotificationMessageType.Success
          );
        }
      });
    }
  };

  validateForm() {
    var item;
    if (this.state.name === "") {
      item = document.getElementById("name");
      item.focus();
      ShowNotification(
        "Tên công ty không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.taxCode === "") {
      item = document.getElementById("taxCode");
      item.focus();
      ShowNotification(
        "Mã số thuế không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }

    if (this.state.phoneNumber === "") {
      item = document.getElementById("phoneNumber");
      item.focus();
      ShowNotification(
        "Số điện thoại không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.emailCompany === "") {
      item = document.getElementById("emailCompany");
      item.focus();
      ShowNotification(
        "Email không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.emailCompany !== "") {
      const re = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
      if (!re.test(this.state.emailCompany)) {
        item = document.getElementById("emailCompany");
        item.focus();
        ShowNotification("Email không hợp lệ", NotificationMessageType.Error);
        return false;
      }
    }
    if (this.state.provinceId === 0) {
      item = document.getElementsByName("provinceId")[0];
      item.focus();
      ShowNotification(
        "Bạn chưa chọn Thành Phố/Tỉnh",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.districtId === 0) {
      item = document.getElementsByName("districtId")[0];
      item.focus();
      ShowNotification(
        "Bạn chưa chọn Quận/Huyện",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.address === "") {
      item = document.getElementById("address");
      item.focus();
      ShowNotification(
        "Địa chỉ không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }

    if (this.state.fullName === "") {
      item = document.getElementById("fullName");
      item.focus();
      ShowNotification(
        "Họ tên đầy đủ không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }

    if (
      this.state.phoneNumberUser === null ||
      this.state.phoneNumberUser === ""
    ) {
      item = document.getElementById("phoneNumberUser");
      item.focus();
      ShowNotification(
        "Số điện thoại không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }

    if (this.state.identityId === "") {
      item = document.getElementById("identityId");
      item.focus();
      ShowNotification(
        "Số CMND/CCCD không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (!this.state.identityDate) {
      item = document.getElementById("identityDate");
      item.focus();
      ShowNotification(
        "Ngày cấp không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.identityBy === "") {
      item = document.getElementById("identityBy");
      item.focus();
      ShowNotification(
        "Nơi cấp không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }

    return true;
  }

  componentDidMount() {
    this.props.getUserDetail().then((x) => {
      if (this.props.getUser.content) {
        this.setState({
          name: this.props.getUser.content.company.name,
          taxCode: this.props.getUser.content.company.taxCode,
          address: this.props.getUser.content.company.address,
          phoneNumber: this.props.getUser.content.company.phoneNumber,
          faxNumber: this.props.getUser.content.company.faxNumber,
          emailCompany: this.props.getUser.content.company.email,
          provinceId: this.props.getUser.content.company.provinceId,
          provinceObj: {
            id: this.props.getUser.content.company.provinceId,
            name: this.props.getUser.content.company.provinceName,
          },
          districtId: this.props.getUser.content.company.districtId,
          districtObj: {
            id: this.props.getUser.content.company.districtId,
            name: this.props.getUser.content.company.districtName,
          },
          communeId: this.props.getUser.content.company.communeId,
          communeObj: {
            id: this.props.getUser.content.company.communeId,
            name: this.props.getUser.content.company.communeName,
          },
          // emailUsername: this.props.getUser.content.company.address,
          // password: this.props.getUser.content.name,
          // confirmPassword: this.props.getUser.content.name,
          certificateNumber: "",
          disableDistrict: false,
          disableCommune: true,
          fullName: this.props.getUser.content.fullName,
          identityId: this.props.getUser.content.identityId,
          identityDate: new Date(this.props.getUser.content.identityDate),
          identityBy: this.props.getUser.content.identityBy,
          sex: this.props.getUser.content.sex ? "male" : "female",
          phoneNumberUser: this.props.getUser.content.phoneNumber,
        });
      }
    });
    this.props.getProvince();
  }

  backClick = (e) => {
    e.preventDefault();
    history.push(UrlCollect.HomeUser);
  };

  render() {
    return (
      <section>
        <Container className="p-r-0">
          <div className="body-content">
            {/* <Breadcrumb className="title">
              <Breadcrumb.Item
                href={UrlCollect.HomeUser}
                className="breadcrumb-icon-home"
              >
                <img
                  src={require("../../../assets/icon/entypo_home.png")}
                ></img>{" "}
                Trang chủ
              </Breadcrumb.Item>
              <Breadcrumb.Item>Thông tin tài khoản</Breadcrumb.Item>
            </Breadcrumb> */}

            <Form noValidate onSubmit={this.handleSubmit}>
              <Form.Label className="group-info">Thông tin công ty:</Form.Label>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    Tên công ty <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    onChange={this.handleChange}
                    required
                    type="text"
                    value={this.state.name}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Giấy phép hoạt động</Form.Label>
                  <Form.Control
                    name="certificateNumber"
                    onChange={this.handleChange}
                    value={this.state.certificateNumber}
                    type="text"
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    Mã số thuế <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="taxCode"
                    name="taxCode"
                    required
                    type="text"
                    value={this.state.taxCode}
                    onChange={this.handleChange}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>
                    Điện thoại <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={this.onChangeNumber}
                    value={this.state.phoneNumber}
                    required
                    type="text"
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Số fax</Form.Label>
                  <Form.Control
                    name="faxNumber"
                    onChange={this.handleChange}
                    type="text"
                    value={this.state.faxNumber}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>
                    Email <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="emailCompany"
                    name="emailCompany"
                    onChange={this.handleChange}
                    required
                    type="email"
                    value={this.state.emailCompany}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} className="address-register">
                  <Form.Label>
                    Thành Phố/Tỉnh<span className="requite">*</span>
                  </Form.Label>

                  <Autocomplete
                    value={this.state.provinceObj}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({
                          provinceId: value.id,
                          provinceObj: value,
                          disableDistrict: false,
                          districtId: 0,
                          districtObj: { id: 0, name: "" },
                          communeId: 0,
                          communeObj: { id: 0, name: "" },
                        });
                        this.props.getDistrict(value.id);
                      } else {
                        this.setState({
                          provinceId: 0,
                          provinceObj: { id: 0, name: "" },
                          districtId: 0,
                          districtObj: { id: 0, name: "" },
                          disableDistrict: true,
                          communeId: 0,
                          communeObj: { id: 0, name: "" },
                          disableCommune: true,
                        });
                      }
                    }}
                    options={
                      this.props.province.content
                        ? this.props.province.content
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="provinceId"
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} className="address-register">
                  <Form.Label>
                    Quận/Huyện <span className="requite">*</span>
                  </Form.Label>

                  <Autocomplete
                    value={this.state.districtObj}
                    disabled={this.state.disableDistrict}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({
                          districtId: value.id,
                          districtObj: value,
                          disableCommune: false,
                          communeId: 0,
                          communeObj: { id: 0, name: "" },
                        });

                        this.props.getCommune(value.id);
                      } else {
                        this.setState({
                          districtId: 0,
                          districtObj: { id: 0, name: "" },
                          communeId: 0,
                          communeObj: { id: 0, name: "" },
                          disableCommune: true,
                        });
                      }
                    }}
                    options={
                      this.props.district.content
                        ? this.props.district.content
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="districtId"
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} className="address-register">
                  <Form.Label>Phường/Xã</Form.Label>
                  <Autocomplete
                    value={this.state.communeObj}
                    disabled={this.state.disableCommune}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({ communeId: value.id });
                      }
                    }}
                    options={
                      this.props.commune.content
                        ? this.props.commune.content
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Form.Group>
              </Form.Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    Địa chỉ <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="address"
                    name="address"
                    onChange={this.handleChange}
                    value={this.state.address}
                    required
                    type="text"
                  />
                </Form.Group>
              </Row>

              <Form.Label className="group-info">
                Thông tin tài khoản:
              </Form.Label>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    Họ tên đầy đủ <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="fullName"
                    name="fullName"
                    onChange={this.handleChange}
                    value={this.state.fullName}
                    required
                    type="text"
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    Giới tính <span className="requite">*</span>
                  </Form.Label>
                  <div>
                    <Form.Check
                      name="sex"
                      checked={this.state.sex === "male"}
                      onChange={this.handleChange}
                      inline
                      label="Nam"
                      type="radio"
                      value="male"
                    />
                    <Form.Check
                      name="sex"
                      checked={this.state.sex === "female"}
                      onChange={this.handleChange}
                      inline
                      label="Nữ"
                      type="radio"
                      value="female"
                    />
                  </div>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    Số điện thoại <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="phoneNumberUser"
                    name="phoneNumberUser"
                    onChange={this.onChangeNumber}
                    value={this.state.phoneNumberUser}
                    required
                    type="text"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    CMND/CCCD <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="identityId"
                    name="identityId"
                    onChange={this.onChangeNumber}
                    value={this.state.identityId ? this.state.identityId : ""}
                    required
                    type="text"
                  />
                </Form.Group>

                <Form.Group as={Col} className="identityBy">
                  <Form.Label>
                    Ngày cấp <span className="requite">*</span>
                  </Form.Label>
                  <div>
                    <DatePicker
                      id="identityDate"
                      name="identityDate"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.identityDate}
                      onChange={(date) => this.setState({ identityDate: date })}
                    />
                  </div>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    Nơi cấp <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="identityBy"
                    name="identityBy"
                    onChange={this.handleChange}
                    value={this.state.identityBy ? this.state.identityBy : ""}
                    required
                    type="text"
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <div className="center">
                    <Button
                      className="button-back"
                      variant="primary"
                      onClick={this.backClick}
                    >
                      HỦY
                    </Button>
                    <span className="margin-5"></span>
                    <Button
                      className="button-register"
                      variant="primary"
                      type="submit"
                      // onClick={this.registerClick}
                    >
                      LƯU THAY ĐỔI
                    </Button>
                  </div>
                </Form.Group>
              </Form.Row>
            </Form>
          </div>
        </Container>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  province: state.provinceUser,
  district: state.district,
  commune: state.commune,
  getUser: state.getUser,
  updateUserState: state.updateUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // login: userService.login,
      getProvince: provinceService.getProvince,
      getDistrict: districtService.getDistrict,
      getCommune: communeService.getCommune,
      getUserDetail: getUserService.getUserDetail,
      updateUser: updateUserService.updateUser,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
