import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../../loading/loading.store";

import Service from "../../../../../api/api-service";
import { ApiUrl } from "../../../../../api/api-url";
import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";

const service = new Service();

export const EVALUATE_RECORD_DELETE_OK = "EVALUATE_RECORD_DELETE_OK";
export const EVALUATE_RECORD_DELETE_ERR = "EVALUATE_RECORD_DELETE_ERR";

export function evaluateRecordDeleteOk(payload) {
  return {
    type: EVALUATE_RECORD_DELETE_OK,
    payload,
  };
}

export function evaluateRecordDeleteErr(payload) {
  return {
    type: EVALUATE_RECORD_DELETE_ERR,
    payload,
  };
}

export const evaluateRecordDeleteService = {
  evaluateRecordDelete,
};

function evaluateRecordDelete(params) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.EvaluateRecords_Delete + params)
      .then((response) => {
        dispatch(evaluateRecordDeleteOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(evaluateRecordDeleteErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
