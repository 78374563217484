import { GET_BY_ID_OK, GET_BY_ID_ERR } from "./get-by-id.action";

const InitState = {
  error: null,
  content: null,
};

export default function GetByIdReducer(state = InitState, action) {
  switch (action.type) {
    case GET_BY_ID_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_BY_ID_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
