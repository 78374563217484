/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import history from "../../common/history";
import UrlCollect from "../../common/url-collect";
import LoginModal from "../modal/login-modal.jsx";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./start-page.scss";

import "../modal/login-modal.scss";

class StartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
    };

    // this.handleChange = this.handleChange.bind(this);
    const currentLoggin = JSON.parse(sessionStorage.getItem("user"));
    if (currentLoggin) {
      if (currentLoggin.role === "user") {
        history.push(UrlCollect.HomeUser);
      } else {
        history.push(UrlCollect.StartPage);
      }
    }
  }

  componentDidMount() {
    var returnUrl = sessionStorage.getItem("returnUrl");
    if (returnUrl && returnUrl !== "") {
      var login = document.getElementById("login");
      login.click();
    }
  }

  // const [modalShow, setModalShow] = React.useState(false);

  //   useEffect(() => {
  //     //console.log("This only happens ONCE.  But it happens AFTER the initial render.");
  //     //check login

  // }, []);
  render() {
    return (
      <section className="banner">
        <div className="container">
          <div className="home-title-1">DỊCH VỤ CÔNG VỀ</div>
          <div className="home-title-2">
            THẨM DUYỆT THIẾT KẾ DỰ ÁN ĐẦU TƯ XÂY DỰNG
          </div>
          <div className="home-button-group text-center">
            <a
              id="login"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#loginModal"
              onClick={() => this.setState({ modalShow: true })}
            >
              <svg
                width="16"
                height="16"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="sign-in-alt"
                className="svg-inline--fa fa-sign-in-alt fa-w-16 mg-r-5"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z"
                ></path>
              </svg>
              Đăng nhập
            </a>
            <a className="btn btn-primary" href="register">
              <svg
                width="16"
                height="16"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="pen"
                className="svg-inline--fa fa-pen fa-w-16 mg-r-5"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
                ></path>
              </svg>
              Đăng ký
            </a>
          </div>
        </div>
        {this.state.modalShow ? (
          <LoginModal
            show={true}
            onHide={() => this.setState({ modalShow: false })}
          />
        ) : (
          ""
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(StartPage);

// const mapStateToProps = (state) => ({});

// const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//   },
//   userDecisionTimeout: 5000,
// })(connect(mapStateToProps, mapDispatchToProps)(StartPage));
