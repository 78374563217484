/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { provinceService } from "../../../redux/store/province/province.service";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import UrlCollect from "../../../common/url-collect";
import Navbar from "react-bootstrap/Navbar";
import FormControl from "react-bootstrap/FormControl";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { qualifyGetListAllService } from "../../../redux/store/user/qualify/get-list-all.action";
import QualifyDetail from "../qualify/qualify-detail.view";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import history from "../../../common/history";

const pagination = paginationFactory({
  sizePerPage: 10,
  paginationSize: 5,
  //withFirstAndLast: false,
  alwaysShowAllBtns: true,
  //firstPageText: 'trang dau tien',
  hideSizePerPage: true,
  //showTotal: true,
});

class ListQualify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceId: 0,
      keyWord: "",
      id: 0,
      isList: true,
      provinceObj: { id: 0, name: "" },
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    loadCaptchaEnginge(5);
    // Custom css for display capcha
    var canv = document.getElementById("canv");
    canv.parentElement.classList.add("mg-t-5");
    var tag = document.getElementById("reload_href");
    tag.parentElement.classList.add("reload-capcha");
    tag.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise color-black" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/><path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/></svg>';
    this.props.getProvince();
    const searchQualifyDetail = JSON.parse(
      sessionStorage.getItem("SearchQualifyDetail")
    );
    if (searchQualifyDetail) {
      this.setState({
        provinceId: searchQualifyDetail.provinceId,
        provinceObj: {
          id: searchQualifyDetail.provinceId,
          name: searchQualifyDetail.provinceName,
        },
        keyWord: searchQualifyDetail.keyWord,
      });

      sessionStorage.removeItem("SearchQualifyDetail");
    } else {
      this.props.qualifyGetlistAll({});
    }
  }
  handleSearch(e) {
    let user_captcha_value = document.getElementById("user_captcha_input");

    if (validateCaptcha(user_captcha_value.value, false) === true) {
      loadCaptchaEnginge(5);
      user_captcha_value.value = "";
      this.setState({ isList: true });
      var param = {
        provinceId: this.state.provinceId !== 0 ? this.state.provinceId : null,
        keyWord: this.state.keyWord,
      };
      this.props.qualifyGetlistAll(param);
    } else {
      ShowNotification("Nhập mã capcha sai", NotificationMessageType.Error);
    }
  }
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  handleCallback = (childData) => {
    this.setState({ isList: childData });
  };

  render() {
    const columns = [
      {
        headerAttrs: {
          className: "headerFocus",
        },
        dataField: "order",
        text: "STT",
        headerStyle: (colum, colIndex) => {
          return {
            width: "60px",
            textAlign: "center",
            background: "#359ae1",
            color: "white",
          };
        },
        formatter: (e, row, index) => {
          return <span>{index + 1}</span>;
        },
      },
      {
        headerAttrs: {
          colSpan: 2,
          className: "headerFocus",
        },
        dataField: "headquarters",
        text: "Thông tin tổ chức",
        headerStyle: (colum, colIndex) => {
          return { textAlign: "center", background: "#359ae1", color: "white" };
        },
        formatter: (cellContent, row) => {
          return (
            <span>
              Tên cơ quan: {row.companyName} <br />
              Địa chỉ: {row.headquarters}
            </span>
          );
        },
      },
      {
        dataField: "companyName",
        text: "ẩn",
        headerAttrs: {
          hidden: true,
        },
        formatter: (cellContent, row) => {
          return (
            <span>
              Người đại diện: {row.companyName} <br />
              Mã số thuế/Quyết định thành lập: {row.taxCode}
              <br />
              Mã chứng chỉ: {row.qualifyCode}
            </span>
          );
        },
      },
      {
        headerAttrs: {
          className: "headerFocus",
        },
        dataField: "taxCode",
        text: "Chi tiết",
        headerStyle: (colum, colIndex) => {
          return {
            width: "70px",
            textAlign: "center",
            background: "#359ae1",
            color: "white",
          };
        },
        formatter: (cellContent, row) => {
          return (
            <a
              onClick={() =>
                history.push(UrlCollect.QualifyDetail + "/" + row.id)
              }
              className="link-detail"
            >
              Chi tiết tổ chức
            </a>
          );
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.setState({ id: row.id, isList: false });
        // history.push(UrlCollect.QualifyDetail + "/" + row.id);
      },
    };
    const products = [];
    if (
      this.props.qualifyGetListAllState.content &&
      this.props.qualifyGetListAllState.content.items.length > 0
    ) {
      this.props.qualifyGetListAllState.content.items.map((item, index) => {
        products.push(item);
      });
    }
    return (
      <section className="list-qualify">
        <div className="wrapper-content">
          <Container>
            <div className="body-content-list">
              <Breadcrumb className="title">
                <Breadcrumb.Item
                  href={UrlCollect.HomeUser}
                  className="breadcrumb-icon-home"
                >
                  <img
                    alt=""
                    src={require("../../../assets/icon/entypo_home.png")}
                  ></img>{" "}
                  Trang chủ
                </Breadcrumb.Item>
                <Breadcrumb.Item>Tra cứu năng lực</Breadcrumb.Item>
              </Breadcrumb>
              <Navbar className="catalog mg-b-10 mg-t-16" variant="light">
                <Form inline className="nav-search">
                  <Autocomplete
                    className="auto-complete mg-r-10"
                    value={this.state.provinceObj}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({ provinceId: value.id });
                      } else {
                        this.setState({ provinceId: 0 });
                      }
                    }}
                    options={
                      this.props.province.content
                        ? this.props.province.content
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Chọn tỉnh thành"
                        variant="outlined"
                      />
                    )}
                  />

                  <FormControl
                    name="keyWord"
                    type="text"
                    placeholder="Từ khóa"
                    className="mr-sm-2 input-keyword "
                    value={this.state.keyWord}
                    onChange={this.handleChange}
                  />

                  <Form.Control
                    className="mr-sm-2"
                    id="user_captcha_input"
                    name="user_captcha_input"
                    required
                    type="text"
                    placeholder="Mã xác nhận"
                  />

                  <LoadCanvasTemplate />

                  <Button
                    onClick={this.handleSearch}
                    className="button-search m-w-100"
                  >
                    Tra cứu
                  </Button>
                </Form>
              </Navbar>
              <span className={this.state.isList ? "" : "display-none"}>
                <BootstrapTable
                  rowEvents={rowEvents}
                  keyField="id"
                  data={products}
                  columns={columns}
                  pagination={pagination}
                  classes="table-striped"
                  rowClasses="mytable-hover-row wrap-text"
                  hover
                />
              </span>
              <span className={this.state.isList ? "display-none" : ""}>
                {this.state.isList ? (
                  ""
                ) : (
                  <QualifyDetail
                    id={this.state.id}
                    parentCallback={this.handleCallback}
                  />
                )}
              </span>
            </div>
          </Container>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  province: state.provinceUser,
  qualifyGetListAllState: state.qualifyGetListAll,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProvince: provinceService.getProvince,
      qualifyGetlistAll: qualifyGetListAllService.getListAll,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListQualify);
