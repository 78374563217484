import { DISTRICT_OK, DISTRICT_ERR } from "./district.action";

const InitState = {
  error: null,
  content: null,
};

export default function DistrictReducer(state = InitState, action) {
  switch (action.type) {
    case DISTRICT_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case DISTRICT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
