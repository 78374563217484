import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const HELPING_GET_LIST_ALL_OK = "HELPING_GET_LIST_ALL_OK";
export const HELPING_GET_LIST_ALL_ERR = "HELPING_GET_LIST_ALL_ERR";

export function helpingGetListAllOk(payload) {
  return {
    type: HELPING_GET_LIST_ALL_OK,
    payload,
  };
}

export function helpingGetListAllErr(payload) {
  return {
    type: HELPING_GET_LIST_ALL_ERR,
    payload,
  };
}

export const helpingGetListAllService = {
  getListAll,
};

function getListAll() {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.HelpingGetListAll)
      .then((response) => {
        dispatch(helpingGetListAllOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(helpingGetListAllErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
