import { ApiUrl } from "../../../api/api-url";
import Service from "../../../api/api-service";
import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../loading/loading.store";
import MapDataModels from "../../../models/map-data-model-b/map-data-model";
import JsonGetPlanningByIdModel from "../../../models/map-data-model-b/json-get-planning-by-id-model";
import PlanningCategoriesTypeModel from "../../../models/map-data-model-b/planning-categories-type-model";
import PlanningCategoriesModel from "../../../models/map-data-model-b/planning-categories-model";
import SingleMapInfoMationModel from "../../../models/map-data-model-b/single-map-infomation-model";
import { handleError500 } from "../../../api/api-handle-error";
import * as Page500ErrorType from "../../../common/error-types-500";
import PlanningRelationModel from "../../../models/map-data-model-b/new-planning-relation.model";
import BoundariesModel from "../../../models/map-data-model-b/new-boundaries.model";
import { store } from "../../../index";
import OpenlayerControllerModel from "../../../models/open-layer/openlayer-controller-custom.model";
import GetAllPlanningBoundariesMode from "../../../models/map-data-model-b/get-all-planning-boundaries.model";
import * as map from "../../../common/map";

const service = new Service();

const SAVELOADINGDATASTATE = "MAPDATA/SAVELOADINGDATASTATE";
const SAVEMAPDATA = "MAPDATA/SAVEMAPDATA";
const SAVEPLANNINGRELATIONSHIP = "MAPDATA/SAVEPLANNINGRELATIONSHIP";
const SAVEINFOMATIONLIST = "MAPDATA/SAVEINFOMATIONLIST";
const SAVEINFOMATIONFORTABLE = "MAPDATA/SAVEINFOMATIONFORTABLE";
const SAVEHEADERFORTABLE = "MAPDATA/SAVEHEADERFORTABLE";
const SAVEDATAFILTER = "MAPDATA/SAVEDATAFILTER";
const SAVEPOLYGON = "MAPDATA/SAVEPOLYGON";
const SAVELISTPLANNING = "MAPDATA/SAVELISTPLANNING";
const SAVEPLANNINGCATEGORIESTYPE = "MAPDATA/SAVEPLANNINGCATEGORIESTYPE";
const SAVEPLANNINGCATEGORIESAll = "MAPDATA/SAVEPLANNINGCATEGORIESALL";
const MERGEDATABOUDARIESTOLIST = "MAPDATA/MERGEDATABOUDARIESTOLIST";
const SAVEALLBOUNDARIESOFALLPLANNING = "MAPDATA/SAVEALLBOUNDARIESOFALLPLANNING";
const LIST_BOUNDARIES = "MAPDATA/LIST_BOUNDARIES";
const SAVEMAPBASE = "MAPDATA/SAVEMAPBASE";

//--- config min max zoom
const getMaxZoomByPlanningTypeId = (planningTypeId: number): number => {
  switch (planningTypeId) {
    case 2:
      return 16;
    case 1:
      return 18;
    case 3:
      return 20;
    default:
      return 20;
  }
};

const getMinZoomByPlanningTypeId = (planningTypeId: number): number => {
  switch (planningTypeId) {
    case 2:
      return 1;
    case 1:
      return 12;
    case 3:
      return 14;
    default:
      return 1;
  }
};

const getZIndexByPlanningTypeId = (planningTypeId: number): number => {
  switch (planningTypeId) {
    case 2:
      return 1;
    case 1:
      return 2;
    case 3:
      return 3;
    default:
      return 4;
  }
};

const createMergeDataBoudariesToList = (data: BoundariesModel) => ({
  type: MERGEDATABOUDARIESTOLIST,
  data: data,
});

const createSavePlanningCategoriesAll = (data: PlanningCategoriesModel[]) => ({
  type: SAVEPLANNINGCATEGORIESAll,
  data: data,
});
const createSavePlanningCategoriesTypes = (
  data: PlanningCategoriesTypeModel[]
) => ({
  type: SAVEPLANNINGCATEGORIESTYPE,
  data: data,
});
const createSaveInfomationList = (data: SingleMapInfoMationModel[]) => ({
  type: SAVEINFOMATIONLIST,
  data: data,
});
const createSaveInfomationForTable = (data: any[]) => ({
  type: SAVEINFOMATIONFORTABLE,
  data: data,
});
const createSaveHeaderForTable = (data: any[]) => ({
  type: SAVEHEADERFORTABLE,
  data: data,
});
const createSaveDataFilter = (data: any) => ({
  type: SAVEDATAFILTER,
  data: data,
});
const createListPolygon = (data: any) => ({
  type: SAVEPOLYGON,
  data: data,
});
const createSaveLoadingDataState = (data: boolean) => ({
  type: SAVELOADINGDATASTATE,
  data: data,
});
const createSaveMapData = (data: MapDataModels.MapDataModels) => ({
  type: SAVEMAPDATA,
  data: data,
});
const createMapBase = (data: any) => ({
  type: SAVEMAPBASE,
  data: data,
});
const createSavePlanningRelationship = (data: PlanningRelationModel[]) => ({
  type: SAVEPLANNINGRELATIONSHIP,
  data: data,
});
const createSaveAllBoundariesFromAllPlanning = (data: any) => ({
  type: SAVEALLBOUNDARIESOFALLPLANNING,
  data: data,
});
const intListBounds = (data: any) => ({
  type: LIST_BOUNDARIES,
  data: data,
});

//--- call api

export const GetAllPlanningBoundariesGeomText = () => {
  return (dispatch: Function) => {
    dispatch(AddOneSlotToLoadingQueue);
    const params = new URLSearchParams();
    return service
      .get(ApiUrl.GetAllBoundariesOfAllPlanning, params)
      .then((res) => {
        if (!res.err && res.content) {
          let arrCoordinatePolygon: number[][][] = [];
          const ListBoundaries: GetAllPlanningBoundariesMode[] = res.content;
          ListBoundaries.map((boundariesObject) => {
            boundariesObject.minZoom = getMinZoomByPlanningTypeId(
              boundariesObject.planningTypeId
            );
            boundariesObject.maxZoom = getMaxZoomByPlanningTypeId(
              boundariesObject.planningTypeId
            );
            boundariesObject.zIndex = getZIndexByPlanningTypeId(
              boundariesObject.planningTypeId
            );
            arrCoordinatePolygon.push(
              map.convertStringToArray(boundariesObject.geomText)
            );
          });
          dispatch(createListPolygon(arrCoordinatePolygon));
        } else {
          // console.log("Error:" + res.err);
        }
        dispatch(intListBounds(res.content));
        return res;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
};
export const GetAllPlanningBoundaries = () => {
  return (dispatch: Function) => {
    dispatch(AddOneSlotToLoadingQueue);
    const params = new URLSearchParams();
    return service
      .get(ApiUrl.GetAllBoundariesOfAllPlanning, params)
      .then((res) => {
        if (!res.err && res.content) {
          const ListBoundaries: GetAllPlanningBoundariesMode[] = res.content;
          ListBoundaries.map((boundariesObject) => {
            boundariesObject.minZoom = getMinZoomByPlanningTypeId(
              boundariesObject.planningTypeId
            );
            boundariesObject.maxZoom = getMaxZoomByPlanningTypeId(
              boundariesObject.planningTypeId
            );
            boundariesObject.zIndex = getZIndexByPlanningTypeId(
              boundariesObject.planningTypeId
            );
          });
          if (ListBoundaries.length > 0) {
            const idIntervalCheck = setInterval(() => {
              const openLayerController: OpenlayerControllerModel = store?.getState()
                .openlayer.openLayerController;
              if (openLayerController) {
                openLayerController.functionDisplayAllBoundariesOfAllPlanning(
                  ListBoundaries
                );
                console.log("run run");
                clearInterval(idIntervalCheck);
              }
            }, 250);
          }
        } else {
        }
        dispatch(intListBounds(res.content));
        return res;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
};

// const convertStringToArray = (stringPolygon: string) => {
//   let arrCoordinatePolygon: number[][] = [];
//   if (!stringPolygon || stringPolygon.length < 8) return arrCoordinatePolygon;
//   const stringArray = stringPolygon.substring(9, stringPolygon.length - 2).split(",");
//   stringArray.map((string) => {
//     let arr = [];
//     const res = string.split(" ");
//     arr.push(parseFloat(res[1]));
//     arr.push(parseFloat(res[0]));
//     arrCoordinatePolygon.push(arr);
//   });
//   return arrCoordinatePolygon;
// };

export const GetListPlanningRelationByPlanningId = (planingId: number) => {
  return (dispatch: Function) => {
    dispatch(AddOneSlotToLoadingQueue);
    const params = new URLSearchParams();
    params.append("id", String(planingId));
    return service
      .get(ApiUrl.GetPlanningRelationshipByPlanningId, params)
      .then((res) => {
        if (!res.err && res.content) {
          const ResultContent = res.content;
          if (ResultContent)
            dispatch(createSavePlanningRelationship(ResultContent));
        } else {
        }
        return res;
      })
      .catch((err) => {
        handleError500(err, Page500ErrorType.ERRORGETAPI);
      })
      .finally(() => {
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
};

export const SaveInfomationList = (data: any[]) => {
  return (dispatch: Function) => {
    dispatch(createSaveInfomationList(data));
  };
};

export const SaveInformationForTable = (data: any[]) => {
  return (dispatch: Function) => {
    dispatch(createSaveInfomationForTable(data));
  };
};
export const SaveHeaderForTable = (data: any[]) => {
  return (dispatch: Function) => {
    dispatch(createSaveHeaderForTable(data));
  };
};
export const SaveMapBase = (data: any) => {
  return (dispatch: Function) => {
    dispatch(createMapBase(data));
  };
};
export const SaveDataFilter = (data: any[]) => {
  return (dispatch: Function) => {
    dispatch(createSaveDataFilter(data));
  };
};

export const GetMapDetailByPlanningId = (planingId: number) => {
  return (dispatch: Function) => {
    dispatch(AddOneSlotToLoadingQueue);
    dispatch(createSaveLoadingDataState(false));
    const params = new URLSearchParams();
    params.append("id", String(planingId));
    return service
      .get(ApiUrl.GetMapPlanningById, params)
      .then((res) => {
        if (!res.err && res.content) {
          const ResultContent: MapDataModels.MapDataModels = res.content;
          if (ResultContent) dispatch(createSaveMapData(ResultContent));
        } else {
          dispatch(
            createSaveMapData({
              center: "",
              extent: "",
              id: 0,
              "map_planning-relationship": [],
              max_zoom: 0,
              min_zoom: 0,
              name: "",
              planing_id: "",
              projection: "",
              z_index: 0,
              zoom: 0,
              map_setting: [],
            })
          );
        }
        dispatch(createSaveLoadingDataState(true));
        return res;
      })
      .catch((err) => {
        handleError500(err, Page500ErrorType.ERRORGETAPI);
      })
      .finally(() => {
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
};

export const GetAllPlanning = () => {
  return (dispatch: Function) => {
    dispatch(AddOneSlotToLoadingQueue);
    const params = new URLSearchParams();
    return service
      .get(ApiUrl.GetAllPlanningByTypeId, params)
      .then((res) => {
        if (!res.err && res.content) {
          const ResultContent: JsonGetPlanningByIdModel[] = res.content;
          if (ResultContent.length > 0) {
            const finalResul: PlanningCategoriesModel[] = [];
            ResultContent.map((planningObject) => {
              if (finalResul.length === 0) {
                finalResul.push({
                  id: planningObject.planningTypeId,
                  name: planningObject.planningTypeName,
                  plannings: [{ ...planningObject, nameTb: "", geomText: "" }],
                  minZoom: getMinZoomByPlanningTypeId(
                    planningObject.planningTypeId
                  ),
                  maxZoom: getMaxZoomByPlanningTypeId(
                    planningObject.planningTypeId
                  ),
                });
              } else {
                let categoriesIsNotExist = true;
                finalResul.map((planningCategories) => {
                  if (planningCategories.id === planningObject.planningTypeId) {
                    planningCategories.plannings.push({
                      ...planningObject,
                      nameTb: "",
                      geomText: "",
                    });
                    categoriesIsNotExist = false;
                    return;
                  }
                });
                if (categoriesIsNotExist) {
                  finalResul.push({
                    id: planningObject.planningTypeId,
                    name: planningObject.planningTypeName,
                    plannings: [
                      { ...planningObject, nameTb: "", geomText: "" },
                    ],
                    minZoom: getMinZoomByPlanningTypeId(
                      planningObject.planningTypeId
                    ),
                    maxZoom: getMaxZoomByPlanningTypeId(
                      planningObject.planningTypeId
                    ),
                  });
                }
              }
            });
            dispatch(createSavePlanningCategoriesAll(finalResul));
          }
        } else {
          // console.log("Error:" + res.err);
        }
        return res;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
};

export const GetAllPlanningCategoriesType = () => {
  return (dispatch: Function) => {
    dispatch(AddOneSlotToLoadingQueue);
    const params = new URLSearchParams();
    return service
      .get(ApiUrl.PlanningType, params)
      .then((res) => {
        if (!res.err && res.content) {
          const ResultContent: PlanningCategoriesTypeModel[] = res.content;
          if (ResultContent.length > 0)
            dispatch(createSavePlanningCategoriesTypes(ResultContent));
        } else {
          // console.log("Error:" + res.err);
        }
        return res;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
};

const InitState = {
  hasLoadingData: false,
  mapData: {
    center: "0,0",
    extent: "0,0,0,0",
    id: -1,
    "map_planning-relationship": [],
    map_setting: [],
    max_zoom: 20,
    min_zoom: 10,
    name: "",
    planing_id: -1,
    projection: "EPSG:4326",
    z_index: 0,
    zoom: 14,
  },
  planningRelationShips: [],
  map: {},
  infomations: [],
  dataForTable: [],
  dataFilter: {},
  listPlanning: [],
  listPlanningCategories: [],
  listPlanningCategoriesAll: [],
  listPlanningBoundaries: [],
  headerTable: [],
  listPolygon: [],
};

export default function MapDataReducer(state = InitState, action: any) {
  switch (action.type) {
    case SAVELOADINGDATASTATE:
      return { ...state, hasLoadingData: action.data };
    case SAVEMAPDATA:
      return { ...state, mapData: action.data };
    case SAVEPLANNINGRELATIONSHIP:
      return { ...state, planningRelationShips: action.data };
    case SAVELISTPLANNING:
      return { ...state, listPlanning: action.data };
    case SAVEPLANNINGCATEGORIESTYPE:
      return { ...state, listPlanningCategories: action.data };
    case SAVEPLANNINGCATEGORIESAll:
      return { ...state, listPlanningCategoriesAll: action.data };
    case SAVEINFOMATIONLIST:
      return { ...state, infomations: action.data };
    case SAVEINFOMATIONFORTABLE:
      return { ...state, dataForTable: action.data };
    case SAVEHEADERFORTABLE:
      return { ...state, headerTable: action.data };
    case SAVEMAPBASE:
      return { ...state, map: action.data };
    case SAVEDATAFILTER:
      return { ...state, dataFilter: action.data };
    case SAVEPOLYGON:
      return { ...state, listPolygon: action.data };
    case MERGEDATABOUDARIESTOLIST: {
      const boundariesData = action.data;
      const listPlanningCategoriesAll: PlanningCategoriesModel[] =
        state.listPlanningCategoriesAll;
      listPlanningCategoriesAll.map((planningCategories) => {
        planningCategories.plannings.map((planningObject) => {
          if (planningObject.id === boundariesData.planningId) {
            planningObject.geomText = boundariesData.geomText;
            planningObject.nameTb = boundariesData.nameTb;
          }
        });
      });
      return { ...state, listPlanningCategoriesAll: listPlanningCategoriesAll };
    }
    case LIST_BOUNDARIES:
      return { ...state, listBoundaries: action.data };
    default:
      return state;
  }
}
