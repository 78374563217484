/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UrlCollect from "../../../common/url-collect";
//import "./header.scss";
//import "./mobile.scss";
import "../../../components/user/sidebar/sidebar-user.scss";
import { userService } from "../../../redux/store/user/acc-user.action";
import UpdateUser from "../update-user/update-user.view";
import ChangePassword from "../change-password/change-password.view";
import ListFiles from "../list-files/list-files.view";
import Constants from "../../../common/constants";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import history from "../../../common/history";

class UserAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDisplay: this.props.match.params.view,
      classActive: "",
    };
    this.logoutClick = this.logoutClick.bind(this);
    // this.menuClick = this.menuClick.bind(this);
  }

  logoutClick() {
    this.props.logout(this.state);
  }
  // menuClick(view) {
  //   history.push(UrlCollect.UserAdmin + view);
  //   // this.setState({ viewDisplay: view });
  // }

  render() {
    return (
      <div className="wrapper-content">
        {this.state.viewDisplay === Constants.ViewUser ? (
          <Breadcrumb className="title">
            <Breadcrumb.Item
              href={UrlCollect.HomeUser}
              className="breadcrumb-icon-home"
            >
              <img
                alt=""
                src={require("../../../assets/icon/entypo_home.png")}
              ></img>{" "}
              Trang chủ
            </Breadcrumb.Item>
            <Breadcrumb.Item>Thông tin tài khoản</Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          ""
        )}
        {this.state.viewDisplay === Constants.ViewFile0 ? (
          <Breadcrumb className="title">
            <Breadcrumb.Item
              href={UrlCollect.HomeUser}
              className="breadcrumb-icon-home"
            >
              <img
                alt=""
                src={require("../../../assets/icon/entypo_home.png")}
              ></img>{" "}
              Trang chủ
            </Breadcrumb.Item>
            <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile0}>
              Hồ sơ của bạn
            </Breadcrumb.Item>
            <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile0}>
              Hồ sơ chưa nộp
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          ""
        )}
        {this.state.viewDisplay === Constants.ViewFile1 ? (
          <Breadcrumb className="title">
            <Breadcrumb.Item
              href={UrlCollect.HomeUser}
              className="breadcrumb-icon-home"
            >
              <img
                alt=""
                src={require("../../../assets/icon/entypo_home.png")}
              ></img>{" "}
              Trang chủ
            </Breadcrumb.Item>
            <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile0}>
              Hồ sơ của bạn
            </Breadcrumb.Item>
            <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile1}>
              Hồ sơ đã nộp
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          ""
        )}
        {this.state.viewDisplay === Constants.ViewFile2 ? (
          <Breadcrumb className="title">
            <Breadcrumb.Item
              href={UrlCollect.HomeUser}
              className="breadcrumb-icon-home"
            >
              <img
                alt=""
                src={require("../../../assets/icon/entypo_home.png")}
              ></img>{" "}
              Trang chủ
            </Breadcrumb.Item>
            <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile0}>
              Hồ sơ của bạn
            </Breadcrumb.Item>
            <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile2}>
              Hồ sơ cần bổ sung
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          ""
        )}
        {this.state.viewDisplay === Constants.ViewFile1000 ? (
          <Breadcrumb className="title">
            <Breadcrumb.Item
              href={UrlCollect.HomeUser}
              className="breadcrumb-icon-home"
            >
              <img
                alt=""
                src={require("../../../assets/icon/entypo_home.png")}
              ></img>{" "}
              Trang chủ
            </Breadcrumb.Item>
            <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile0}>
              Hồ sơ của bạn
            </Breadcrumb.Item>
            <Breadcrumb.Item
              href={UrlCollect.UserAdmin + Constants.ViewFile1000}
            >
              Kết quả
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          ""
        )}
        {this.state.viewDisplay === Constants.ViewChangePassword ? (
          <Breadcrumb className="title">
            <Breadcrumb.Item
              href={UrlCollect.HomeUser}
              className="breadcrumb-icon-home"
            >
              <img
                alt=""
                src={require("../../../assets/icon/entypo_home.png")}
              ></img>{" "}
              Trang chủ
            </Breadcrumb.Item>
            <Breadcrumb.Item>Đặt lại mật khẩu</Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          ""
        )}

        <div className="wrapper">
          <span className="sidebar-top">
            <nav
              id="sidebar"
              className={this.props.sidebar.isActive ? "active" : ""}
            >
              <ul className="list-unstyled components">
                {/**Thông tin tài khoản ======================================================================= */}
                <li
                  className={
                    this.state.viewDisplay === Constants.ViewUser
                      ? "active"
                      : ""
                  }
                >
                  <a
                    href={UrlCollect.UserAdmin + Constants.ViewUser}
                    // data-toggle="collapse"
                    // aria-expanded="false"
                    // class="dropdown-toggle"
                    // onClick={() => this.menuClick(Constants.ViewUser)}
                  >
                    <img
                      alt=""
                      src={require("../../../assets/icon/address-book.png")}
                    ></img>{" "}
                    Thông tin tài khoản
                  </a>
                </li>

                {/**Hồ sơ của bạn ======================================================================= */}
                <li>
                  <a
                    href="#accountSubmenu"
                    data-toggle="collapse"
                    aria-expanded={
                      this.state.viewDisplay === Constants.ViewFile0 ||
                      this.state.viewDisplay === Constants.ViewFile1 ||
                      this.state.viewDisplay === Constants.ViewFile2 ||
                      this.state.viewDisplay === Constants.ViewFile1000
                        ? true
                        : false
                    }
                    className="dropdown-toggle"
                  >
                    <img
                      alt=""
                      src={require("../../../assets/icon/b_file-alt.png")}
                    ></img>{" "}
                    {"  "} Hồ sơ của bạn
                  </a>
                  <ul
                    className={
                      this.state.viewDisplay === Constants.ViewFile0 ||
                      this.state.viewDisplay === Constants.ViewFile1 ||
                      this.state.viewDisplay === Constants.ViewFile2 ||
                      this.state.viewDisplay === Constants.ViewFile1000
                        ? "collapse list-unstyled show"
                        : "collapse list-unstyled"
                    }
                    id="accountSubmenu"
                  >
                    <li
                      className={
                        this.state.viewDisplay === Constants.ViewFile0
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href={UrlCollect.UserAdmin + Constants.ViewFile0}
                        // href={UrlCollect.ListFiles + "/0"}
                        // onClick={() => this.menuClick(Constants.ViewFile0)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        {"  "} Hồ sơ chưa nộp
                      </a>
                    </li>
                    <li
                      className={
                        this.state.viewDisplay === Constants.ViewFile1
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        // onClick={() => this.menuClick(Constants.ViewFile1)}
                        href={UrlCollect.UserAdmin + Constants.ViewFile1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        {"  "} Hồ sơ đã nộp
                      </a>
                    </li>
                    <li
                      className={
                        this.state.viewDisplay === Constants.ViewFile2
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        // onClick={() => this.menuClick(Constants.ViewFile2)}
                        href={UrlCollect.UserAdmin + Constants.ViewFile2}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        {"  "} Hồ sơ cần bổ sung
                      </a>
                    </li>
                    <li
                      className={
                        this.state.viewDisplay === Constants.ViewFile1000
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        // onClick={() => this.menuClick(Constants.ViewFile1000)}
                        href={UrlCollect.UserAdmin + Constants.ViewFile1000}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        {"  "} Kết quả
                      </a>
                    </li>
                  </ul>
                </li>

                {/**Đặt lại mật khẩu ======================================================================= */}
                <li
                  className={
                    this.state.viewDisplay === Constants.ViewChangePassword
                      ? "active"
                      : ""
                  }
                >
                  <a
                    href={UrlCollect.UserAdmin + Constants.ViewChangePassword}
                    // onClick={() => this.menuClick(Constants.ViewChangePassword)}
                    // href={UrlCollect.ChangePassword}
                    // data-toggle="collapse"
                    // aria-expanded="false"
                    // class="dropdown-toggle"
                  >
                    <img
                      alt=""
                      src={require("../../../assets/icon/bi_shield-lock-fill.png")}
                    ></img>{" "}
                    {"  "} Đặt lại mật khẩu
                  </a>
                  {/** ========================================== */}
                </li>
                {/**Đăng xuất ======================================================================= */}
                <li>
                  <a
                    onClick={this.logoutClick}
                    href="#configSubmenu"
                    // data-toggle="collapse"
                    // aria-expanded="false"
                    // class="dropdown-toggle"
                  >
                    <img
                      alt=""
                      src={require("../../../assets/icon/logout-icon 1.png")}
                    ></img>{" "}
                    {"  "} Đăng xuất
                  </a>
                  {/** ========================================== */}
                </li>
              </ul>
            </nav>
          </span>
          <div className="row no-gutters content-admin">
            <div className="content-admin">
              {this.state.viewDisplay === Constants.ViewUser ? (
                <UpdateUser />
              ) : (
                ""
              )}
              {this.state.viewDisplay === Constants.ViewFile0 ? (
                <ListFiles status={0} />
              ) : (
                ""
              )}
              {this.state.viewDisplay === Constants.ViewFile1 ? (
                <ListFiles status={1} />
              ) : (
                ""
              )}
              {this.state.viewDisplay === Constants.ViewFile2 ? (
                <ListFiles status={2} />
              ) : (
                ""
              )}

              {this.state.viewDisplay === Constants.ViewFile1000 ? (
                <ListFiles status={1000} />
              ) : (
                ""
              )}
              {this.state.viewDisplay === Constants.ViewChangePassword ? (
                <ChangePassword />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebarUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: userService.logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin);
