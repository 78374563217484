import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const UPDATESTEP1_OK = "UPDATESTEP1_OK";
export const UPDATESTEP1_ERR = "UPDATESTEP1_ERR";

export function updateStep1Ok(payload) {
  return {
    type: UPDATESTEP1_OK,
    payload,
  };
}

export function updateStep1Err(payload) {
  return {
    type: UPDATESTEP1_ERR,
    payload,
  };
}

export const updateStep1Service = {
  updateStep1,
};

function updateStep1(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdateStep1, data)
      .then((response) => {
        dispatch(updateStep1Ok(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(updateStep1Err(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
