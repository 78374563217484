import { SET_STATE_SIDEBAR_USER } from "./sidebar.action";

const InitState = {
  isActive: false,
};

export default function SideBarUserReducer(state = InitState, action) {
  switch (action.type) {
    case SET_STATE_SIDEBAR_USER: {
      return {
        ...state,
        isActive: action.payload,
      };
    }
    default:
      return state;
  }
}
