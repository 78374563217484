/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Breadcrumb from "react-bootstrap/Breadcrumb";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import UrlCollect from "../../../common/url-collect";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { helpingGetListAllService } from "../../../redux/store/user/helping/get-list-all.action";
import history from "../../../common/history";

const pagination = paginationFactory({
  sizePerPage: 15,
  paginationSize: 5,
  //withFirstAndLast: false,
  alwaysShowAllBtns: true,
  //firstPageText: 'trang dau tien',
  hideSizePerPage: true,
  //showTotal: true,
});

class ListHelping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      isList: true,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleCallback = (childData) => {
    this.setState({ isList: childData });
  };

  componentDidMount() {
    this.props.helpingGetlistAll();
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }
  render() {
    const columns = [
      {
        headerAttrs: {
          hidden: true,
        },
        dataField: "order",
        text: "STT",
        formatter: (content, row, index) => {
          return (
            <a>
              <span className="requite">{index + 1}</span>{" "}
              <span className="item-title">{row.title}</span>
            </a>
          );
        },
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.setState({ id: row.id, isList: false });
        history.push(UrlCollect.Helping + "/" + row.id);
      },
    };
    const products = [];
    if (
      this.props.helpingGetlistAllState.content &&
      this.props.helpingGetlistAllState.content.items.length > 0
    ) {
      this.props.helpingGetlistAllState.content.items.map((item, index) => {
        products.push(item);
      });
    }
    return (
      <section className="list-helping">
        <div className="wrapper-content">
          <Container>
            <Breadcrumb className="title">
              <Breadcrumb.Item
                href={UrlCollect.HomeUser}
                className="breadcrumb-icon-home"
              >
                <img
                  alt=""
                  src={require("../../../assets/icon/entypo_home.png")}
                ></img>{" "}
                Trang chủ
              </Breadcrumb.Item>
              <Breadcrumb.Item href={UrlCollect.ListHelping}>
                Hướng dẫn
              </Breadcrumb.Item>
              {/* {this.state.isList ? (
                ""
              ) : (
                <Breadcrumb.Item>
                  {this.props.helpingGetDetailState.content
                    ? this.props.helpingGetDetailState.content.title
                    : ""}
                </Breadcrumb.Item>
              )} */}
            </Breadcrumb>
            {/* <span className={this.state.isList ? "" : "display-none"}> */}
            <BootstrapTable
              rowEvents={rowEvents}
              keyField="id"
              data={products}
              columns={columns}
              pagination={pagination}
              rowClasses="wrap-text"
            />
            {/* </span>
            <span className={this.state.isList ? "display-none" : ""}>
              {this.state.isList ? (
                ""
              ) : (
                <Helping
                  id={this.state.id}
                  parentCallback={this.handleCallback}
                />
              )}
            </span> */}
          </Container>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  helpingGetlistAllState: state.helpingGetListAll,
  helpingGetDetailState: state.helpingGetDetail,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      helpingGetlistAll: helpingGetListAllService.getListAll,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListHelping);
