/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { provinceService } from "../../../redux/store/province/province.service";
import Row from "react-bootstrap/Row";
import { qualifyGetDetailService } from "../../../redux/store/user/qualify/get-detail.action";
import DateFormat from "dateformat";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import UrlCollect from "../../../common/url-collect";
import Navbar from "react-bootstrap/Navbar";
import FormControl from "react-bootstrap/FormControl";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import history from "../../../common/history";
import { qualifyGetListAllService } from "../../../redux/store/user/qualify/get-list-all.action";

// const qualifyId = -1;

class QualifyDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceId: 0,
      provinceName: "",
      keyWord: "",
    };
    this.qualifyId = this.props.match.params.id;

    this.backClick = this.backClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // Custom css for display capcha
    loadCaptchaEnginge(5);
    var canv = document.getElementById("canv");
    canv.parentElement.classList.add("mg-t-5");
    var tag = document.getElementById("reload_href");
    tag.parentElement.classList.add("reload-capcha");
    tag.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise color-black" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/><path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/></svg>';
    this.props.getProvince();
    this.props.qualifyGetDetail(this.qualifyId).then((x) => {
      if (this.props.qualifyGetDetailState.content) {
      } else {
        history.push(UrlCollect.Page404);
      }
    });
  }

  backClick = (e) => {
    e.preventDefault();
    // this.props.parentCallback(true);
    history.push(UrlCollect.ListQualify);
  };

  handleSearch(e) {
    let user_captcha_value = document.getElementById("user_captcha_input");

    if (validateCaptcha(user_captcha_value.value, false) === true) {
      loadCaptchaEnginge(5);
      user_captcha_value.value = "";
      this.setState({ isList: true });
      var param = {
        provinceId: this.state.provinceId !== 0 ? this.state.provinceId : null,
        keyWord: this.state.keyWord,
      };
      this.props.qualifyGetlistAll(param).then((x) => {
        sessionStorage.setItem(
          "SearchQualifyDetail",
          JSON.stringify({
            provinceId: this.state.provinceId,
            provinceName: this.state.provinceName,
            keyWord: this.state.keyWord,
          })
        );
        history.push(UrlCollect.ListQualify);
      });
    } else {
      ShowNotification("Nhập mã capcha sai", NotificationMessageType.Warning);
    }
  }
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  render() {
    let companyName;
    let shortName;
    let taxCode;
    let issuedDate;
    let issuedBy;
    let headquarters;
    let office;
    let representer;
    let roleName;
    let qualifyCode;
    if (this.props.qualifyGetDetailState.content) {
      companyName = this.props.qualifyGetDetailState.content.companyName;
      shortName = this.props.qualifyGetDetailState.content.shortName;
      taxCode = this.props.qualifyGetDetailState.content.taxCode;
      issuedDate = this.props.qualifyGetDetailState.content.issuedDate;
      headquarters = this.props.qualifyGetDetailState.content.headquarters;
      office = this.props.qualifyGetDetailState.content.office;
      representer = this.props.qualifyGetDetailState.content.representer;
      roleName = this.props.qualifyGetDetailState.content.roleName;
      issuedBy = this.props.qualifyGetDetailState.content.issuedBy;
      qualifyCode = this.props.qualifyGetDetailState.content.qualifyCode;
    }
    return (
      <section className="list-qualify">
        <div className="wrapper-content">
          <Container>
            <div className="body-content-list">
              <Breadcrumb className="title">
                <Breadcrumb.Item
                  href={UrlCollect.HomeUser}
                  className="breadcrumb-icon-home"
                >
                  <img
                    alt=""
                    src={require("../../../assets/icon/entypo_home.png")}
                  ></img>{" "}
                  Trang chủ
                </Breadcrumb.Item>
                <Breadcrumb.Item href={UrlCollect.ListQualify}>
                  Tra cứu năng lực
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {this.props.qualifyGetDetailState.content
                    ? this.props.qualifyGetDetailState.content.companyName
                    : ""}
                </Breadcrumb.Item>
              </Breadcrumb>
              <Navbar className="catalog mg-b-10 mg-t-16" variant="light">
                <Form inline className="nav-search">
                  <Autocomplete
                    className="auto-complete mg-r-10"
                    value={this.state.provinceObj}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({
                          provinceId: value.id,
                          provinceName: value.name,
                        });
                      } else {
                        this.setState({
                          provinceId: 0,
                          provinceName: "",
                        });
                      }
                    }}
                    options={
                      this.props.province.content
                        ? this.props.province.content
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Chọn tỉnh thành"
                        variant="outlined"
                      />
                    )}
                  />

                  <FormControl
                    name="keyWord"
                    type="text"
                    placeholder="Từ khóa"
                    className="mr-sm-2 input-keyword "
                    value={this.state.keyWord}
                    onChange={this.handleChange}
                  />

                  <Form.Control
                    className="mr-sm-2"
                    id="user_captcha_input"
                    name="user_captcha_input"
                    required
                    type="text"
                    placeholder="Mã xác nhận"
                  />

                  <LoadCanvasTemplate />

                  <Button
                    onClick={this.handleSearch}
                    className="button-search m-w-100"
                  >
                    Tra cứu
                  </Button>
                </Form>
              </Navbar>
              <section className="qualify-detail">
                <div className="qualify-detail">
                  <span className="label-item">TÊN TỔ CHỨC: {companyName}</span>
                  <br />
                  <span className="label-item"> Tên viết tắt: </span>{" "}
                  {shortName}
                  <br />
                  <span className="label-item">
                    Mã chứng chỉ năng lực HĐXD:{" "}
                  </span>
                  <span className="item-mark">{qualifyCode}</span>
                  <br />
                  <span className="label-item">Địa chỉ trụ sở chính: </span>
                  {headquarters}
                  <br />
                  <span className="label-item">
                    Địa chỉ văn phòng đại diện, văn phòng chi nhánh:{" "}
                  </span>
                  {office}
                  <br />
                  <span className="label-item">
                    Người đại diện theo pháp luật:{" "}
                  </span>
                  {representer} <span className="label-item">| Chức vụ:</span>{" "}
                  {roleName}
                  <br />
                  <span className="label-item">
                    Mã số thuế/ Quyết định thành lập:{" "}
                  </span>
                  <span className="item-mark">{taxCode}</span>
                  <br />
                  <span className="label-item">Ngày cấp: </span>
                  {DateFormat(issuedDate, "dd/mm/yyyy")}
                  <br />
                  <span className="label-item">Cơ quan cấp: </span>
                  <span className="item-mark">{issuedBy}</span>
                </div>
                <section>
                  <Form.Label className="group-info"></Form.Label>
                  <Row>
                    <Col>
                      <Button
                        className="button-register"
                        variant="primary"
                        type="submit"
                        onClick={this.backClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                          />
                        </svg>{" "}
                        QUAY LẠI
                      </Button>
                    </Col>
                  </Row>
                  <Form.Label className="group-info"></Form.Label>
                </section>
              </section>
            </div>
          </Container>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  province: state.provinceUser,
  qualifyGetDetailState: state.qualifyGetDetail,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProvince: provinceService.getProvince,
      qualifyGetDetail: qualifyGetDetailService.getDetail,
      qualifyGetlistAll: qualifyGetListAllService.getListAll,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QualifyDetail);
