/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./home-user.scss";
import "./home-entry.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { getByCodeService } from "../../redux/store/user/get-by-code/get-by-code.action";
import UrlCollect from "../../common/url-collect";
import history from "../../common/history";
import Constants from "../../common/constants";
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType,DomainUserSide } from "../../utils/configuration";

class HomeUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image1: "",
      image2: "",
      image3: "",
      image4: "",
      image5: "",
      image6: "",
      keyword: "",
    };
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(e) {
    // e.preventDefault();
    if (this.state.keyword) {
      var param = "code=" + this.state.keyword;
      this.props.getByCode(param).then((x) => {
        if (this.props.getByCodeUser.content) {
          // var status;
          // if (this.props.getByCodeUser.content.status === 1) {
          //   status = Constants.ViewFile1;
          // } else if (this.props.getByCodeUser.content.status === 2) {
          //   status = Constants.ViewFile2;
          // } else {
          //   status = Constants.ViewFile0;
          // }

          history.push(
            UrlCollect.FileDetail + "/" + this.props.getByCodeUser.content.id
          );
        } else {
          ShowNotification(
            "Không tìm thấy mã hồ sơ",
            NotificationMessageType.Warning
          );
        }
      });
    }
  }

  render() {
    const submitDocument = (type) => {
      window.location = DomainUserSide+"appraisal-and-approval/"+type;
    }

    return (
      <section className="home-user">
        <div className="banner">
          <div className="container">
            <div className="home-title-1">DỊCH VỤ CÔNG VỀ</div>
            <div className="home-title-2">
              THẨM DUYỆT THIẾT KẾ DỰ ÁN ĐẦU TƯ XÂY DỰNG
            </div>
            <div className="search">
              <div>
                <InputGroup className="input-search-w">
                  <FormControl
                    required
                    placeholder="Nhập mã hồ sơ"
                    aria-label="Nhập mã hồ sơ"
                    aria-describedby="basic-addon2"
                    value={this.state.keyword ? this.state.keyword : ""}
                    onChange={(e) => {
                      this.setState({ keyword: e.target.value });
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={this.handleSearch}
                      className="button-search"
                    >
                      TRA CỨU
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="step">
          <Row className="clear-margin">
            <Col className="step-info">
              <div className="center">
                Quy trình <span className="c-yellow">4</span> bước thẩm duyệt{" "}
                <br />
                thiết kế trực tuyến
              </div>
            </Col>
            <Col className="step-info">
              <h2>1</h2>
              <div>
                <span className="c-yellow">CHUẨN BỊ HỒ SƠ</span> <br />
                theo hướng dẫn tại đây
              </div>
            </Col>
            <Col className="step-info">
              <h2>2</h2>
              <div>
                <span className="c-yellow">NỘP HỒ SƠ</span> <br />
                Nộp hồ sơ (bản mềm)
              </div>
            </Col>
            <Col className="step-info">
              <h2>3</h2>
              <div>
                <span className="c-yellow">NỘP LỆ PHÍ</span>
                <br />
                Qua cổng thanh toán
              </div>
            </Col>
            <Col className="step-info clear-border">
              <h2>4</h2>
              <div>
                <span className="c-yellow">NHẬN KẾT QUẢ </span> <br />
                Ngay trên hệ thống
              </div>
            </Col>
          </Row>
        </div>
        <div className="category">
          <Container className="category-margin">
            <Row>
              <Col>
                <div className="entry-box">
                  <div className="entry-box2">
                      <div className="entry-icon-box">
                        <img src={require("../../assets/icon/icon1.jpg")}></img>
                      </div>
                      <div className="entry-title-box">
                          <a href="#">LẬP BÁO CÁO NGHIÊN CỨU TIỀN KHẢ THI <br/> ĐẦU TƯ XÂY DỰNG</a>
                      </div>
                      <div className="entry-item-box">
                        <div className="entry-item-title">
                          <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                        </div>
                        <div className="apply-document-box">
                          <div className="apply-document-button">
                            Nộp hồ sơ
                          </div>
                        </div>
                        <div className="view-help-box">
                          <div className="view-help-button">
                            Xem chi tiết
                          </div>
                        </div>
                        <div className="cb"></div>
                      </div>
                      <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                      <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                      <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="entry-box">
                  <div className="entry-box2">
                    <div className="entry-icon-box">
                      <img src={require("../../assets/icon/icon2.jpg")}></img>
                    </div>
                    <div className="entry-title-box">
                      <a href="#">Thẩm định Báo cáo nghiên cứu khả thi, BCKTKT của cơ quan chuyên môn về xây dựng</a>
                    </div>
                    <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                    <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                    <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                    <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="entry-box">
                  <div className="entry-box2">
                    <div className="entry-icon-box">
                      <img src={require("../../assets/icon/icon3.jpg")}></img>
                    </div>
                    <div className="entry-title-box">
                      <a href="#">Thẩm định thiết kế xây dựng triển khai sau thiết kế cơ sở</a>
                    </div>
                    <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                    <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                    <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                    <div className="entry-item-box">
                      <div className="entry-item-title">
                        <a href="#" onClick={() => submitDocument(1)}>Đối với dự án sử dụng vốn ngân sách nhà nước</a>
                      </div>
                      <div className="apply-document-box">
                        <div className="apply-document-button">
                          Nộp hồ sơ
                        </div>
                      </div>
                      <div className="view-help-box">
                        <div className="view-help-button">
                          Xem chi tiết
                        </div>
                      </div>
                      <div className="cb"></div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  getByCodeUser: state.getByCodeUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // SetStateSidebar: setStateSidebarUser,
      getByCode: getByCodeService.getByCode,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeUser);

// const mapStateToProps = (state) => ({
//   isLoading: state.app.loading,
//   listPolygon: state.mapData.listPolygon,
// });

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       showLoading: appActions.ShowLoading,
//       getSlideShow: homePageStore.GetSlideShow,
//       getAllPlanningByTypeId: homePageStore.GetAllPlanningByTypeId,
//       GetAllPlanningBoundariesGeomText:
//         MapDataStore.GetAllPlanningBoundariesGeomText,
//     },
//     dispatch
//   );

// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//   },
//   userDecisionTimeout: 5000,
// })(connect(mapStateToProps, mapDispatchToProps)(HomeUser));
