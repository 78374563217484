export const PROVINCE_OK = "PROVINCE_OK";
export const PROVINCE_ERR = "PROVINCE_ERR";

export function getProvinceOk(payload) {
  return {
    type: PROVINCE_OK,
    payload,
  };
}

export function getProvinceErr(payload) {
  return {
    type: PROVINCE_ERR,
    payload,
  };
}
