import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../loading/loading.store";
//import history from "../../../../common/history";
//import UrlCollect from "../../../../common/url-collect";
import Service from "../../../api/api-service";
import { PROVINCE_ERR, PROVINCE_OK } from "./province.action";
import { ApiUrl } from "../../../api/api-url";
const service = new Service();

export const provinceService = {
  getProvince,
};

function getProvince() {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetLookupProvince)
      .then((response) => {
        dispatch({ type: PROVINCE_OK, payload: response });
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch({ type: PROVINCE_ERR, payload: error });
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
}
