import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../loading/loading.store";
//import history from "../../../../common/history";
//import UrlCollect from "../../../../common/url-collect";
import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
const service = new Service();

export const COMMUNE_OK = "COMMUNE_OK";
export const COMMUNE_ERR = "COMMUNE_ERR";

export function getCommuneOk(payload) {
  return { type: COMMUNE_OK, payload };
}

export function getCommuneErr(payload) {
  return { type: COMMUNE_ERR, payload };
}

export const communeService = {
  getCommune,
};

function getCommune(districtId) {
  var params = "districtId=" + districtId;
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetLookupCommune, params)
      .then((response) => {
        dispatch(getCommuneOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getCommuneErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
}
