import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const COMPANY_USER_OK = "COMPANY_USER_OK";
export const COMPANY_USER_ERR = "COMPANY_USER_ERR";

export function companyUserOk(payload) {
  return {
    type: COMPANY_USER_OK,
    payload,
  };
}

export function companyUserErr(payload) {
  return {
    type: COMPANY_USER_ERR,
    payload,
  };
}

export const companyService = {
  getCompanyByTaxCode,
};

function getCompanyByTaxCode(taxCode) {
  var param = { taxCode: taxCode };
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.GetCompanyByTaxCode, param)
      .then((response) => {
        dispatch(companyUserOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(companyUserErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
