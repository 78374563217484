/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./list-files.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import FormControl from "react-bootstrap/FormControl";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getListAllService } from "../../../redux/store/user/getListAll/getListAll.action";
import FileDetail from "../file-detail/file-detail.view";
import FileResult from "../file-result/file-result.view";
import DateFormat from "dateformat";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";
import DeleteDialog from "../../../modules/modal/dialog-delete/dialog-delete.view";
import { evaluateRecordDeleteService } from "../../../redux/store/user/evaluateRecords/delete/delete.action";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";

const pagination = paginationFactory({
  sizePerPage: 10,
  paginationSize: 5,
  //withFirstAndLast: false,
  alwaysShowAllBtns: true,
  //firstPageText: 'trang dau tien',
  hideSizePerPage: true,
  //showTotal: true,
});

class ListFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // id: 0,
      // isList: true,
      keyword: "",
      isOpen: false,
      fileId: 0,
    };
    this.status = this.props.status;
    // this.isList = true;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getlistAllData({
      status: this.status,
    });
    // Check user when search by code in home and view detail
    // if (this.props.getByCodeUser.content) {
    //   this.setState({
    //     id: this.props.getByCodeUser.content.id,
    //     isList: false,
    //   });
    // }
    // if (this.props.getByIdRedirectUser.content) {
    //   this.setState({
    //     id: this.props.getByIdRedirectUser.content.id,
    //     isList: false,
    //   });
    // }
  }

  handleDelete(e, row) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isOpen: true, fileId: row.id });
  }

  DeleteFile = (e) => {
    e.preventDefault();
    this.props.evaluateRecordDelete(this.state.fileId).then((x) => {
      if (this.props.evaluateRecordDeleteState.content) {
        ShowNotification(
          "Xóa hồ sơ thành công",
          NotificationMessageType.Success
        );
      }
      this.setState({ isOpen: false });
      this.props.getlistAllData({
        status: this.status,
      });
    });
  };

  handleCallback = (childData) => {
    this.setState({ isList: childData });
  };

  handleSearch = () => {
    this.props.getlistAllData({
      status: this.status,
      keyword: this.state.keyword,
    });
  };

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  render() {
    let nameStatus;
    if (this.status === 0) {
      nameStatus = "Hồ sơ chưa nộp";
    } else if (this.status === 1) {
      nameStatus = "Hồ sơ đã nộp";
    } else if (this.status === 2) {
      nameStatus = "Hồ sơ cần bổ sung";
    } else if (this.status === 1000) {
      nameStatus = "Kết quả";
    }
    var columns = [];
    if (this.status === 0 || this.status === 2) {
      columns = [
        {
          dataField: "name",
          text: "Tên dự án",
          headerAttrs: {
            hidden: true,
          },
        },
        {
          dataField: "sendDate",
          text: "Ngày gửi",
          headerAttrs: {
            hidden: true,
          },
          formatter: (cellContent, row) => {
            return DateFormat(row.sendDate, "dd/mm/yyyy");
          },
        },
        {
          dataField: "statusName",
          text: "Trạng thái",
          headerAttrs: {
            hidden: true,
          },
        },
        {
          dataField: "sendDate",
          text: "Ngày gửi",
          headerAttrs: {
            hidden: true,
          },
          formatter: (cellContent, row) => {
            return (
              <div
                className="delete-action"
                onClick={(e) => this.handleDelete(e, row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                </svg>{" "}
              </div>
            );
          },
        },
      ];
    } else {
      columns = [
        {
          dataField: "name",
          text: "Tên dự án",
          headerAttrs: {
            hidden: true,
          },
        },
        {
          dataField: "sendDate",
          text: "Ngày gửi",
          headerAttrs: {
            hidden: true,
          },
          formatter: (cellContent, row) => {
            return DateFormat(row.sendDate, "dd/mm/yyyy");
          },
        },
        {
          dataField: "statusName",
          text: "Trạng thái",
          headerAttrs: {
            hidden: true,
          },
        },
      ];
    }

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.isList = false;
        // this.setState({ id: row.id, isList: false });
        if (this.status == 1000) {
          history.push(UrlCollect.FileResult + "/" + row.id);
        } else {
          history.push(UrlCollect.FileDetail + "/" + row.id);
        }
      },
    };
    const products = [
      // {
      //   name: "abc",
      //   sendDate: new Date(),
      //   statusName: "status",
      //   id: 1,
      // },
      // {
      //   name: "abc",
      //   sendDate: new Date(),
      //   statusName: "status",
      //   id: 2,
      // },
      // {
      //   name: "abc",
      //   sendDate: new Date(),
      //   statusName: "status",
      //   id: 3,
      // },
    ];
    if (
      this.props.getListAllUser.content &&
      this.props.getListAllUser.content.items.length > 0
    ) {
      this.props.getListAllUser.content.items.map((item, index) => {
        products.push(item);
      });
    }
    return (
      <span>
        <span>
          <section className="list-files">
            <Container className="p-r-0">
              <div className="body-content-list">
                {/* <Breadcrumb className="title">
                  <Breadcrumb.Item
                    href={UrlCollect.HomeUser}
                    className="breadcrumb-icon-home"
                  >
                    <img
                      src={require("../../../assets/icon/entypo_home.png")}
                    ></img>{" "}
                    Trang chủ
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={UrlCollect.UserAdmin + Constants.ViewFile0}
                  >
                    Hồ sơ của bạn
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{nameStatus}</Breadcrumb.Item>
                </Breadcrumb> */}
                <Navbar className="catalog" variant="light">
                  <Nav className="mr-auto">
                    <Nav.Link className="label-category">{nameStatus}</Nav.Link>
                  </Nav>
                  <Form inline>
                    <FormControl
                      name="keyword"
                      type="text"
                      placeholder="Tìm kiếm"
                      className="mr-sm-2"
                      value={this.state.keyword}
                      onChange={this.handleChange}
                    />
                    <Button
                      onClick={this.handleSearch}
                      className="button-search"
                    >
                      Tìm kiếm
                    </Button>
                  </Form>
                </Navbar>
                {this.props.getListAllUser.content ? (
                  this.props.getListAllUser.content.items.length > 0 ? (
                    <BootstrapTable
                      rowEvents={rowEvents}
                      keyField="id"
                      data={products}
                      columns={columns}
                      pagination={pagination}
                      classes="table-striped"
                      rowClasses="mytable-hover-row wrap-text row-pointer"
                      hover
                    />
                  ) : (
                    <div className="p-10">Không có hồ sơ nào phù hợp</div>
                  )
                ) : (
                  ""
                )}
              </div>
            </Container>
          </section>
        </span>
        {/* <span className={this.state.isList ? "display-none" : ""}>
          {this.state.isList ? (
            ""
          ) : this.status === 1000 ? (
            <FileResult
              id={this.state.id}
              parentCallback={this.handleCallback}
            />
          ) : (
            <FileDetail
              id={this.state.id}
              parentCallback={this.handleCallback}
            />
          )}
        </span> */}
        <DeleteDialog
          isOpen={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          onSuccess={this.DeleteFile}
        />
      </span>
    );
  }
}
const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  getListAllUser: state.getListAllUser,
  getByCodeUser: state.getByCodeUser,
  getByIdRedirectUser: state.getByIdRedirectUser,
  evaluateRecordDeleteState: state.evaluateRecordDelete,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // SetStateSidebar: setStateSidebarUser,
      getlistAllData: getListAllService.getListAll,
      evaluateRecordDelete: evaluateRecordDeleteService.evaluateRecordDelete,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListFiles);
