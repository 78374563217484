/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Row from "react-bootstrap/Row";
import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
import { changePasswordService } from "../../../redux/store/user/change-password/change-password.action";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import { userService } from "../../../redux/store/user/acc-user.action";

const initialState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  backClick = (e) => {
    e.preventDefault();
    history.push(UrlCollect.HomeUser);
  };

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }
  validateForm() {
    var item;
    if (this.state.currentPassword === "") {
      item = document.getElementById("currentPassword");
      item.focus();
      ShowNotification(
        "Mật khẩu hiện tại không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.newPassword === "") {
      item = document.getElementById("newPassword");
      item.focus();
      ShowNotification(
        "Mật khẩu mới không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.confirmPassword === "") {
      item = document.getElementById("confirmPassword");
      item.focus();
      ShowNotification(
        "Xác nhận mật khẩu không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.newPassword !== this.state.confirmPassword) {
      item = document.getElementById("confirmPassword");
      item.focus();
      ShowNotification(
        "xác nhận mật khẩu không đúng",
        NotificationMessageType.Error
      );
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.validateForm()) {
      var params = {
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
      };
      this.props.changePassword(params).then((x) => {
        if (this.props.changePasswordState.content) {
          this.setState(initialState);
          ShowNotification(
            "Thay đổi mật khẩu thành công",
            NotificationMessageType.Success
          );
          this.props.logout(this.state);
        }
      });
    }
  };

  componentDidMount() {}

  render() {
    return (
      <section className="change-password">
        <Container className="p-r-0">
          <div className="body-content">
            {/* <Breadcrumb className="title">
            <Breadcrumb.Item
              href={UrlCollect.HomeUser}
              className="breadcrumb-icon-home"
            >
              <img src={require("../../../assets/icon/entypo_home.png")}></img>{" "}
              Trang chủ
            </Breadcrumb.Item>
            <Breadcrumb.Item>Đặt lại mật khẩu</Breadcrumb.Item>
          </Breadcrumb> */}
            <Form noValidate onSubmit={this.handleSubmit}>
              <Row>
                <Col xs={3} md={2}></Col>
                <Col xs={12} md={8}>
                  <Form.Row>
                    <Form.Group as={Col} className="mg-t-5">
                      <Form.Label>Mật khẩu hiện tại</Form.Label>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Control
                        id="currentPassword"
                        name="currentPassword"
                        value={this.state.currentPassword}
                        onChange={this.handleChange}
                        required
                        type="password"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group className="mg-t-5" as={Col}>
                      <Form.Label>Mật khẩu mới</Form.Label>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Control
                        id="newPassword"
                        name="newPassword"
                        value={this.state.newPassword}
                        onChange={this.handleChange}
                        required
                        type="password"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group className="mg-t-5" as={Col}>
                      <Form.Label>Xác nhận mật khẩu</Form.Label>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Control
                        id="confirmPassword"
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        onChange={this.handleChange}
                        required
                        type="password"
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
                <Col xs={3} md={2}></Col>
              </Row>
              <Row>
                <Col xs={3} md={2}></Col>
                <Col xs={12} md={8} className="center">
                  <Button
                    onClick={this.backClick}
                    className="button-back w-120"
                    variant="primary"
                  >
                    Hủy
                  </Button>
                  <span className="margin-5"></span>
                  <Button
                    className="button-register"
                    variant="primary"
                    type="submit"
                  >
                    ĐẶT LẠI MẬT KHẨU
                  </Button>
                </Col>
                <Col xs={3} md={2}></Col>
              </Row>
              <Row></Row>
            </Form>
          </div>
        </Container>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  changePasswordState: state.changePassword,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // SetStateSidebar: setStateSidebarUser,
      changePassword: changePasswordService.changePassword,
      logout: userService.logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
