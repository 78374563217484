import React from "react";
import HeaderLoginView from "../header/header-login.view";
import MenuUserAdminView from "../menu/menu-user-admin.view";
import FooterView from "../footer/footer.view.jsx";
import LoadingWithApiQueue from "../loading/loading-with-queue.view";
import { setStateSidebarUser } from "../../redux/store/user/sidebar/sidebar.action";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./layout.scss";
import { Container } from "react-bootstrap";

class LayoutUserAdminView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyHeight: null,
    };
    this.clickBtnToggler = this.clickBtnToggler.bind(this);
  }

  clickBtnToggler() {
    if (this.props.sidebarUser.isActive) {
      this.props.SetStateSidebar(false);
      document.body.style.overflow = "unset";
    } else {
      this.props.SetStateSidebar(true);
      document.body.style.overflow = "hidden";
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="layout-container">
        <HeaderLoginView match={this.props.match} />
        <MenuUserAdminView match={this.props.match} />
        <Container>
          <div
            onClick={this.clickBtnToggler}
            className={
              this.props.sidebarUser.isActive ? "overlay active" : "overlay"
            }
          ></div>
          <div style={this.state.bodyHeight}>{this.props.children}</div>
          {/* <div className="wrapper wrapper-content">
            <SidebarUser />
            <div className="row no-gutters content-admin">
              <div className="content-admin" style={this.state.bodyHeight}>
                {this.props.children}
              </div>
            </div>
          </div> */}
          {/* <div style={this.state.bodyHeight}>{this.props.children}</div> */}
        </Container>

        <FooterView />
        <LoadingWithApiQueue />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarUser: state.sidebarUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SetStateSidebar: setStateSidebarUser,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutUserAdminView);
