/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";

import Row from "react-bootstrap/Row";
import { helpingGetDetailService } from "../../../redux/store/user/helping/get-detail.action";

class Helping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceId: 0,
      keyWord: "",
    };
    this.helpingId = this.props.match.params.id;

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.helpingGetDetail(this.helpingId).then((x) => {
      if (this.props.helpingGetDetailState.content) {
      } else {
        history.push(UrlCollect.Page404);
      }
    });
  }

  backClick = (e) => {
    e.preventDefault();
    history.push(UrlCollect.ListHelping);
    // this.props.parentCallback(true);
  };

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }
  render() {
    return (
      <section className="helping-detail">
        <div className="wrapper-content">
          <Container>
            <Breadcrumb className="title">
              <Breadcrumb.Item
                href={UrlCollect.HomeUser}
                className="breadcrumb-icon-home"
              >
                <img
                  alt=""
                  src={require("../../../assets/icon/entypo_home.png")}
                ></img>{" "}
                Trang chủ
              </Breadcrumb.Item>
              <Breadcrumb.Item href={UrlCollect.ListHelping}>
                Hướng dẫn
              </Breadcrumb.Item>

              <Breadcrumb.Item>
                {this.props.helpingGetDetailState.content
                  ? this.props.helpingGetDetailState.content.title
                  : ""}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
              {this.props.helpingGetDetailState.content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.helpingGetDetailState.content.content,
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <section>
              <Form.Label className="group-info"></Form.Label>
              <Row>
                <Col>
                  <Button
                    className="button-register"
                    variant="primary"
                    type="submit"
                    onClick={this.backClick}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      />
                    </svg>{" "}
                    QUAY LẠI
                  </Button>
                </Col>
              </Row>
              <Form.Label className="group-info"></Form.Label>
            </section>
          </Container>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  helpingGetDetailState: state.helpingGetDetail,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      helpingGetDetail: helpingGetDetailService.getDetail,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Helping);
