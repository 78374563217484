import { GET_BY_CODE_OK, GET_BY_CODE_ERR } from "./get-by-code.action";

const InitState = {
  error: null,
  content: null,
};

export default function GetByCodeReducer(state = InitState, action) {
  switch (action.type) {
    case GET_BY_CODE_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_BY_CODE_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
