import {
  NOTIFICATION_GET_LIST_ALL_OK,
  NOTIFICATION_GET_LIST_ALL_ERR,
} from "./get-list-all.action";

const InitState = {
  error: null,
  content: null,
};

export default function NotificationGetListAllReducer(
  state = InitState,
  action
) {
  switch (action.type) {
    case NOTIFICATION_GET_LIST_ALL_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case NOTIFICATION_GET_LIST_ALL_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
