import {
  LOGIN_USER_OK,
  LOGIN_USER_ERR,
  GET_USER_DETAIL_OK,
  GET_USER_DETAIL_ERR,
  LOGIN_USER_OUT,
} from "./acc-user.action";

const InitState = {
  error: null,
  content: null,
};

export default function LoginUserReducer(state = InitState, action) {
  switch (action.type) {
    case LOGIN_USER_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case LOGIN_USER_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case GET_USER_DETAIL_OK: {
      return {
        ...state,
        error: action.payload.content,
        content: null,
      };
    }
    case GET_USER_DETAIL_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case LOGIN_USER_OUT: {
      return InitState;
    }
    default:
      return state;
  }
}
