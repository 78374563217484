import { combineReducers } from "redux";
import MapToolsPanelReducers from "./store/open-layer/map-tools-panels.store";
import AppReducer from "../core/app.store";
import LoadingReducer from "../redux/store/loading/loading.store";
import MapDataReducer from "./store/map-data/map-data.store";
import OpenLayerReducer from "./store/open-layer/open-layer.store";
import StatementReducer from "./store/statement/statement.store";

//Admin
// import SidebarReducer from "./store/admin/sidebar/sidebar.store";
// import LoginAdminReducer from "./store/admin/account-adm/acc-adm.store";
// import WeblinkReducer from "./store/admin/weblink/weblink.store";
// import ProvinceAdmReducer from "./store/admin/province/province.store";
// import DistrictAdmReducer from "./store/admin/district/district.store";
// import CommuneAdmReducer from "./store/admin/commune/commune.store";
// import ProjectAdmReducer from "./store/admin/project/project.store";
// import DepartmentAdmReducer from "./store/admin/department/department.store";
// import PositionAdmReducer from "./store/admin/position/position.store";
// import NewsCategoryAdmReducer from "./store/admin/news/news.store";

//user
import ProvinceReducer from "./store/province/province.store";
import DistrictReducer from "./store/district/district.store";
import CommuneReducer from "./store/commune/commune.store";
import LoginUserReducer from "./store/user/acc-user.store";
import ProjectReducer from "./store/user/project/project.store";
import CompanyReducer from "./store/user/company/company.store";
import getUserReducer from "./store/user/get-user/get-user.store";
import CreatStep1Reducer from "./store/user/createStep1/createStep1.store";
import evaluateRecordsTypeReducer from "./store/user/evaluate-records-type/evaluate-records-type.store";
import UploadFileReducer from "./store/user/uploadFile/uploadFile.store";
import finishCreateReducer from "./store/user/finishCreate/finishCreate.store";
import SidebarUserReducer from "./store/user/sidebar/sidebar.store";
import GetListAllReducer from "./store/user/getListAll/getListAll.store";
import GetByIdReducer from "./store/user/get-by-id/get-by-id.store";
import GetByIdRedirectReducer from "./store/user/get-by-id/get-by-id-redirect.store";
import UpdateUserReducer from "./store/user/update-user/update-user.store";
import RegisterReducer from "./store/user/register/register.store";
import ChangePasswordReducer from "./store/user/change-password/change-password.store";
import QualifyGetListAllReducer from "./store/user/qualify/get-list-all.store";
import QualifyGetDetailReducer from "./store/user/qualify/get-detail.store";
import HelpingGetListAllReducer from "./store/user/helping/get-list-all.store";
import HelpingGetDetailReducer from "./store/user/helping/get-detail.store";
import NotificationGetListAllReducer from "./store/user/notification/get-list-all.store";
import NotificationGetDetailReducer from "./store/user/notification/get-detail.store";
import GetByCodeReducer from "./store/user/get-by-code/get-by-code.store";
import UpdateStep1Reducer from "./store/user/updateStep1/updateStep1.store";
import DeleteFileReducer from "./store/user/delete-file/delete-file.store";
import ForgotPasswordReducer from "./store/user/forgot-password/forgot-password.store";
import EvaluateRecordDeleteReducer from "./store/user/evaluateRecords/delete/delete.store";
import GetListLinkServiceReducer from "./store/user/administrative/get-list-link-service/get-list-link-service.store";

export default combineReducers({
  app: AppReducer,
  mapToolsPanel: MapToolsPanelReducers,
  loadingState: LoadingReducer,
  mapData: MapDataReducer,
  openlayer: OpenLayerReducer,
  statement: StatementReducer,
  // user store
  provinceUser: ProvinceReducer,
  district: DistrictReducer,
  commune: CommuneReducer,
  user: LoginUserReducer,
  projectUser: ProjectReducer,
  companyUser: CompanyReducer,
  getUser: getUserReducer,
  createStep1: CreatStep1Reducer,
  updateStep1: UpdateStep1Reducer,
  evaluateRecordsType: evaluateRecordsTypeReducer,
  evaluateRecordUploadFile: UploadFileReducer,
  evaluateRecordDeleteFile: DeleteFileReducer,

  finishCreate: finishCreateReducer,
  sidebarUser: SidebarUserReducer,
  getListAllUser: GetListAllReducer,
  getByIdUser: GetByIdReducer,
  getByIdRedirectUser: GetByIdRedirectReducer,
  getByCodeUser: GetByCodeReducer,
  updateUser: UpdateUserReducer,
  register: RegisterReducer,
  changePassword: ChangePasswordReducer,
  qualifyGetListAll: QualifyGetListAllReducer,
  qualifyGetDetail: QualifyGetDetailReducer,
  helpingGetListAll: HelpingGetListAllReducer,
  helpingGetDetail: HelpingGetDetailReducer,
  notificationGetListAll: NotificationGetListAllReducer,
  notificationGetDetail: NotificationGetDetailReducer,
  forgotPassword: ForgotPasswordReducer,
  evaluateRecordDelete: EvaluateRecordDeleteReducer,
  getListLinkService: GetListLinkServiceReducer,

  // admin store
  // sidebar: SidebarReducer,
  // admin: LoginAdminReducer,
  // weblink: WeblinkReducer,
  // provinceAdm: ProvinceAdmReducer,
  // districtAdm: DistrictAdmReducer,
  // communeAdm: CommuneAdmReducer,
  // projectAdm: ProjectAdmReducer,
  // departmentAdm: DepartmentAdmReducer,
  // positionAdm: PositionAdmReducer,
  // newsAdm: NewsCategoryAdmReducer,
});
