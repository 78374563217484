import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const GET_LIST_ALL_OK = "GET_LIST_ALL_OK";
export const GET_LIST_ALL_ERR = "GET_LIST_ALL_ERR";

export function getListAllOk(payload) {
  return {
    type: GET_LIST_ALL_OK,
    payload,
  };
}

export function getListAllErr(payload) {
  return {
    type: GET_LIST_ALL_ERR,
    payload,
  };
}

export const getListAllService = {
  getListAll,
};

function getListAll(params) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CMSGetListAll, params)
      .then((response) => {
        dispatch(getListAllOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getListAllErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
