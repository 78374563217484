/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./menu.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UrlCollect from "../../common/url-collect";
import { Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

// const LanguageCollect = ViLanguage;

class MenuView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="main-nav menu-top-user">
        <Container>
          <Navbar className="p-3" collapseOnSelect variant="dark">
            <Nav className="mr-auto">
              <Nav.Link href={UrlCollect.HomeUser}>TRANG CHỦ</Nav.Link>
              <Nav.Link href={UrlCollect.ListQualify}>
                TRA CỨU NĂNG LỰC
              </Nav.Link>
              <Nav.Link href={UrlCollect.ListHelping}>HƯỚNG DẪN</Nav.Link>
            </Nav>
          </Navbar>
        </Container>
      </div>
      // <nav className="main-nav">
      //   <div className="container .navbar-dark">
      //     <button
      //       onClick={() => this.clickBtnToggler()}
      //       className="navbar-toggler d-sm-block d-md-none"
      //       type="button"
      //       data-toggle="collapse"
      //       data-target="#navbarNav"
      //       aria-controls="navbarNav"
      //       aria-expanded="false"
      //       aria-label="Toggle navigation"
      //     >
      //       <span className="navbar-toggler-icon"></span>
      //     </button>

      //     <span id="menu-bar" className="menu-mobile d-xl-none">
      //       <svg
      //         width="26"
      //         height="20"
      //         viewBox="0 0 26 20"
      //         fill="none"
      //         xmlns="http://www.w3.org/2000/svg"
      //       >
      //         <path
      //           d="M24.703 19.4414H7.31687C6.60031 19.4414 6.01953 18.8606 6.01953 18.144C6.01953 17.4275 6.60031 16.8467 7.31687 16.8467H24.7025C25.4191 16.8467 25.9999 17.4275 25.9999 18.144C25.9999 18.8606 25.4195 19.4414 24.703 19.4414Z"
      //           fill="white"
      //         />
      //         <path
      //           d="M24.703 11.2978H7.31687C6.60031 11.2978 6.01953 10.717 6.01953 10.0005C6.01953 9.2839 6.60031 8.70312 7.31687 8.70312H24.7025C25.4191 8.70312 25.9999 9.2839 25.9999 10.0005C26.0003 10.717 25.4195 11.2978 24.703 11.2978Z"
      //           fill="white"
      //         />
      //         <path
      //           d="M24.703 3.15425H7.31687C6.60031 3.15425 6.01953 2.57347 6.01953 1.85691C6.01953 1.14035 6.60031 0.55957 7.31687 0.55957H24.7025C25.4191 0.55957 25.9999 1.14035 25.9999 1.85691C25.9999 2.57347 25.4195 3.15425 24.703 3.15425Z"
      //           fill="white"
      //         />
      //         <path
      //           d="M1.74233 3.6995C2.70459 3.6995 3.48466 2.91943 3.48466 1.95717C3.48466 0.994911 2.70459 0.214844 1.74233 0.214844C0.780067 0.214844 0 0.994911 0 1.95717C0 2.91943 0.780067 3.6995 1.74233 3.6995Z"
      //           fill="white"
      //         />
      //         <path
      //           d="M1.74233 11.7425C2.70459 11.7425 3.48466 10.9624 3.48466 10.0001C3.48466 9.03788 2.70459 8.25781 1.74233 8.25781C0.780067 8.25781 0 9.03788 0 10.0001C0 10.9624 0.780067 11.7425 1.74233 11.7425Z"
      //           fill="white"
      //         />
      //         <path
      //           d="M1.74233 19.7854C2.70459 19.7854 3.48466 19.0054 3.48466 18.0431C3.48466 17.0808 2.70459 16.3008 1.74233 16.3008C0.780067 16.3008 0 17.0808 0 18.0431C0 19.0054 0.780067 19.7854 1.74233 19.7854Z"
      //           fill="white"
      //         />
      //       </svg>
      //     </span>
      //     <div className="menu">
      //       <ul className="level0">
      //         <li className="active">
      //           <a href="#">Trang chủ</a>
      //         </li>

      //         <li>
      //           <a href="#">Tra cứu năng lực</a>
      //         </li>
      //         <li>
      //           <a href="#">Hướng dẫn</a>
      //         </li>
      //       </ul>
      //     </div>
      //   </div>
      // </nav>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuView);
