const Constants = {
  ViewUser: "ViewUser",
  ViewFile0: "ViewFile0",
  ViewFile1: "ViewFile1",
  ViewFile2: "ViewFile2",
  ViewFile1000: "ViewFile1000",
  ViewChangePassword: "ViewChangePassword",
};

export default Constants;
