import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const FORGOT_PASSWORD_OK = "FORGOT_PASSWORD_OK";
export const FORGOT_PASSWORD_ERR = "FORGOT_PASSWORD_ERR";

export function forgotPasswordOk(payload) {
  return {
    type: FORGOT_PASSWORD_OK,
    payload,
  };
}

export function forgotPasswordErr(payload) {
  return {
    type: FORGOT_PASSWORD_ERR,
    payload,
  };
}

export const forgotPasswordService = {
  forgotPassword,
};

function forgotPassword(params) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CMSForgotPassword, params)
      .then((response) => {
        dispatch(forgotPasswordOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(forgotPasswordErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
