import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const GET_BY_CODE_OK = "GET_BY_CODE_OK";
export const GET_BY_CODE_ERR = "GET_BY_CODE_ERR";

export function getByCodeOk(payload) {
  return {
    type: GET_BY_CODE_OK,
    payload,
  };
}

export function getByCodeErr(payload) {
  return {
    type: GET_BY_CODE_ERR,
    payload,
  };
}

export const getByCodeService = {
  getByCode,
};

function getByCode(params) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.CMSGetByCode, params)
      .then((response) => {
        dispatch(getByCodeOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getByCodeErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
