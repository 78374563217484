import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const DELETE_FILE_OK = "DELETE_FILE_OK";
export const DELETE_FILE_ERR = "DELETE_FILE_ERR";

export function deleteFileOk(payload) {
  return {
    type: DELETE_FILE_OK,
    payload,
  };
}

export function deleteFileErr(payload) {
  return {
    type: DELETE_FILE_ERR,
    payload,
  };
}

export const deleteFileService = {
  deleteFile,
};

function deleteFile(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.EvaluateRecordsType_DeleteFile + data)
      .then((response) => {
        dispatch(deleteFileOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deleteFileErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
