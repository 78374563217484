import { REGISTER_OK, REGISTER_ERR } from "./register.action";

const InitState = {
  error: null,
  content: null,
};

export default function RegisterReducer(state = InitState, action) {
  switch (action.type) {
    case REGISTER_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case REGISTER_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
