/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Row from "react-bootstrap/Row";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { notificationGetDetailService } from "../../../redux/store/user/notification/get-detail.action";
import DateFormat from "dateformat";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
// const qualifyId = -1;

class NotificationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceId: 0,
      keyWord: "",
    };
    this.notificationId = this.props.match.params.id;

    this.backClick = this.backClick.bind(this);
  }

  componentDidMount() {
    this.props.notificationGetDetail(this.notificationId).then((x) => {
      if (this.props.notificationGetDetailState.content) {
      } else {
        history.push(UrlCollect.Page404);
      }
    });
  }

  backClick = (e) => {
    e.preventDefault();
    // this.props.parentCallback(true);
    history.push(UrlCollect.ListNotification);
  };

  render() {
    return (
      <section className="list-notification">
        <div className="wrapper-content">
          <Container>
            <Breadcrumb className="title">
              <Breadcrumb.Item
                href={UrlCollect.HomeUser}
                className="breadcrumb-icon-home"
              >
                <img
                  alt=""
                  src={require("../../../assets/icon/entypo_home.png")}
                ></img>{" "}
                Trang chủ
              </Breadcrumb.Item>
              <Breadcrumb.Item href={UrlCollect.ListNotification}>
                Thông báo
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="body-content-list">
              <section className="notification-detail">
                <Navbar className="catalog p-t-5 p-b-5">
                  <Nav className="mr-auto">
                    <Nav.Link className="item-notification">
                      {this.props.notificationGetDetailState.content
                        ? this.props.notificationGetDetailState.content.name
                        : ""}
                    </Nav.Link>
                  </Nav>
                  <div className="inline">
                    {this.props.notificationGetDetailState.content
                      ? DateFormat(
                          this.props.notificationGetDetailState.content
                            .sentDate,
                          "dd/mm/yyyy"
                        )
                      : ""}
                  </div>
                </Navbar>
                <div className="content">
                  {this.props.notificationGetDetailState.content ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.notificationGetDetailState.content
                          .content,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <section className="p-l-10">
                  <Form.Label class="group-info"></Form.Label>
                  <Row>
                    <Col>
                      <Button
                        className="button-register"
                        variant="primary"
                        type="submit"
                        onClick={this.backClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                          />
                        </svg>{" "}
                        QUAY LẠI
                      </Button>
                    </Col>
                  </Row>
                  <Form.Label class="group-info"></Form.Label>
                </section>
              </section>
            </div>
          </Container>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  notificationGetDetailState: state.notificationGetDetail,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notificationGetDetail: notificationGetDetailService.getDetail,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetail);
