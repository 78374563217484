import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/css/common.css";
import "./assets/styles/css/header.css";
import "./assets/styles/css/main-nav.css";
import "./assets/styles/css/main.css";
import "./assets/styles/css/footer.css";
import "./assets/styles/css/user.scss";

import { Provider } from "react-redux";
import GlobalStore from "./redux/store";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./components/react-notifications/_customTypes.scss";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

export const store = GlobalStore;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactNotification />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
