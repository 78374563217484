import React from "react";
import HeaderLoginView from "../header/header-login.view";
import MenuView from "../menu/menu.view.jsx";
import FooterView from "../footer/footer.view.jsx";
import LoadingWithApiQueue from "../loading/loading-with-queue.view";

import "./layout.scss";

export default class LayoutDesktopView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyHeight: null,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="layout-container">
        <HeaderLoginView match={this.props.match} />
        <MenuView match={this.props.match} />
        <div style={this.state.bodyHeight}>{this.props.children}</div>
        <FooterView />
        <LoadingWithApiQueue />
      </div>
    );
  }
}
