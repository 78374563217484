/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UrlCollect from "../../../common/url-collect";
//import "./header.scss";
//import "./mobile.scss";
import "./sidebar-user.scss";
import { userService } from "../../../redux/store/user/acc-user.action";
//import "./header.scss";
//import "./mobile.scss";
import Constants from "../../../common/constants";

class SidebarUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // viewDisplay: "",
    };
    this.logoutClick = this.logoutClick.bind(this);
    this.viewDisplay = this.props.viewDisplay;
  }

  logoutClick() {
    this.props.logout(this.state);
  }
  render() {
    return (
      <span className="sidebar-top">
        <nav
          id="sidebar"
          className={this.props.sidebar.isActive ? "active" : ""}
        >
          <ul className="list-unstyled components">
            {/**Thông tin tài khoản ======================================================================= */}
            <li
              className={
                this.viewDisplay === Constants.ViewUser ? "active" : ""
              }
            >
              <a
                href={UrlCollect.UserAdmin + Constants.ViewUser}
                // data-toggle="collapse"
                // aria-expanded="false"
                // class="dropdown-toggle"
                // onClick={() => this.menuClick(Constants.ViewUser)}
              >
                <img
                  alt=""
                  src={require("../../../assets/icon/address-book.png")}
                ></img>{" "}
                Thông tin tài khoản
              </a>
            </li>

            {/**Hồ sơ của bạn ======================================================================= */}
            <li>
              <a
                href="#accountSubmenu"
                data-toggle="collapse"
                aria-expanded={
                  this.viewDisplay === Constants.ViewFile0 ||
                  this.viewDisplay === Constants.ViewFile1 ||
                  this.viewDisplay === Constants.ViewFile2 ||
                  this.viewDisplay === Constants.ViewFile1000
                    ? true
                    : false
                }
                className="dropdown-toggle"
              >
                <img
                  alt=""
                  src={require("../../../assets/icon/b_file-alt.png")}
                ></img>{" "}
                {"  "} Hồ sơ của bạn
              </a>
              <ul
                className={
                  this.viewDisplay === Constants.ViewFile0 ||
                  this.viewDisplay === Constants.ViewFile1 ||
                  this.viewDisplay === Constants.ViewFile2 ||
                  this.viewDisplay === Constants.ViewFile1000
                    ? "collapse list-unstyled show"
                    : "collapse list-unstyled"
                }
                id="accountSubmenu"
              >
                <li
                  className={
                    this.viewDisplay === Constants.ViewFile0 ? "active" : ""
                  }
                >
                  <a
                    href={UrlCollect.UserAdmin + Constants.ViewFile0}
                    // href={UrlCollect.ListFiles + "/0"}
                    // onClick={() => this.menuClick(Constants.ViewFile0)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    {"  "} Hồ sơ chưa nộp
                  </a>
                </li>
                <li
                  className={
                    this.viewDisplay === Constants.ViewFile1 ? "active" : ""
                  }
                >
                  <a
                    // onClick={() => this.menuClick(Constants.ViewFile1)}
                    href={UrlCollect.UserAdmin + Constants.ViewFile1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    {"  "} Hồ sơ đã nộp
                  </a>
                </li>
                <li
                  className={
                    this.viewDisplay === Constants.ViewFile2 ? "active" : ""
                  }
                >
                  <a
                    // onClick={() => this.menuClick(Constants.ViewFile2)}
                    href={UrlCollect.UserAdmin + Constants.ViewFile2}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    {"  "} Hồ sơ cần bổ sung
                  </a>
                </li>
                <li
                  className={
                    this.viewDisplay === Constants.ViewFile1000 ? "active" : ""
                  }
                >
                  <a
                    // onClick={() => this.menuClick(Constants.ViewFile1000)}
                    href={UrlCollect.UserAdmin + Constants.ViewFile1000}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    {"  "} Kết quả
                  </a>
                </li>
              </ul>
            </li>

            {/**Đặt lại mật khẩu ======================================================================= */}
            <li
              className={
                this.viewDisplay === Constants.ViewChangePassword
                  ? "active"
                  : ""
              }
            >
              <a
                href={UrlCollect.UserAdmin + Constants.ViewChangePassword}
                // onClick={() => this.menuClick(Constants.ViewChangePassword)}
                // href={UrlCollect.ChangePassword}
                // data-toggle="collapse"
                // aria-expanded="false"
                // class="dropdown-toggle"
              >
                <img
                  alt=""
                  src={require("../../../assets/icon/bi_shield-lock-fill.png")}
                ></img>{" "}
                {"  "} Đặt lại mật khẩu
              </a>
              {/** ========================================== */}
            </li>
            {/**Đăng xuất ======================================================================= */}
            <li>
              <a
                onClick={this.logoutClick}
                href="#configSubmenu"
                // data-toggle="collapse"
                // aria-expanded="false"
                // class="dropdown-toggle"
              >
                <img
                  alt=""
                  src={require("../../../assets/icon/logout-icon 1.png")}
                ></img>{" "}
                {"  "} Đăng xuất
              </a>
              {/** ========================================== */}
            </li>
          </ul>
        </nav>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebarUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: userService.logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SidebarUser);
