import { UPDATE_USER_OK, UPDATE_USER_ERR } from "./update-user.action";

const InitState = {
  error: null,
  content: null,
};

export default function UpdateUserReducer(state = InitState, action) {
  switch (action.type) {
    case UPDATE_USER_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case UPDATE_USER_ERR: {
      return {
        ...state,
        error: action.payload.errors,
        content: null,
      };
    }
    default:
      return state;
  }
}
