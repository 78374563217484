import React from "react";
import { Route, Redirect } from "react-router-dom";
import UrlCollect from "./common/url-collect";

export const PrivateRoute = ({
  component: Component,
  layout: Layout,
  roles,
  isAdmin,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAdmin) {
        // const currentUser = authenticationService.currentUserValue;
        const currentAdmin = JSON.parse(sessionStorage.getItem("user"));
        if (!currentAdmin) {
          // not logged in so redirect to login page
          return <Redirect to={{ pathname: UrlCollect.AdmLogin }} />;
        }
        // check if route is restricted by role
        if (roles && roles.indexOf(currentAdmin.role) === -1) {
          // role not authorised so redirect DashboardAdmin page
          //return <Redirect to={{ pathname: UrlCollect.DashboardAdmin }} />;
          return <Redirect to={{ pathname: UrlCollect.AdmLogin }} />;
        }

        // authorised so return component
        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      } else {
        const currentUser = JSON.parse(sessionStorage.getItem("user"));

        if (!currentUser) {
          // Set return url when don't login
          sessionStorage.setItem("returnUrl", rest.location.pathname);
          // not logged in so redirect to login page
          return <Redirect to={{ pathname: UrlCollect.StartPage }} />;
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.role) === -1) {
          // Set return url when don't login
          sessionStorage.setItem("returnUrl", rest.location.pathname);
          // role not authorised so redirect Index page
          //return <Redirect to={{ pathname: UrlCollect.HomeUser }} />;
          return <Redirect to={{ pathname: UrlCollect.StartPage }} />;
        }

        // authorised so return component
        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      }
    }}
  />
);
