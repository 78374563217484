import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const CHANGE_PASSWORD_OK = "CHANGE_PASSWORD_OK";
export const CHANGE_PASSWORD_ERR = "CHANGE_PASSWORD_ERR";

export function changePasswordOk(payload) {
  return {
    type: CHANGE_PASSWORD_OK,
    payload,
  };
}

export function changePasswordErr(payload) {
  return {
    type: CHANGE_PASSWORD_ERR,
    payload,
  };
}

export const changePasswordService = {
  changePassword,
};

function changePassword(params) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.ChangePassword, params)
      .then((response) => {
        dispatch(changePasswordOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(changePasswordErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
