import React from "react";
import { Router, Switch } from "react-router-dom";
import RouteComponent from "./route-config.jsx";
import { PrivateRoute } from "./PrivateRoute";
import history from "./common/history";
import routers from "./router";
import UrlCollect from "./common/url-collect";

//--- Loading
import AppLoading from "./components/loading/loading.view";

function App() {
  return (
    <div>
      <AppLoading />

      <Router history={history}>
        <Switch>
          {routers.map((prop, key) => {
            if (
              prop.path === UrlCollect.AdmLogin ||
              prop.path === UrlCollect.StartPage ||
              prop.path === UrlCollect.Register ||
              prop.path === UrlCollect.ForgotPassword
            ) {
              return (
                <RouteComponent
                  exact
                  layout={prop.layout}
                  component={prop.component}
                  path={prop.path}
                  key={key}
                />
              );
            } else {
              return (
                <PrivateRoute
                  exact
                  component={prop.component}
                  layout={prop.layout}
                  roles={prop.roles}
                  isAdmin={prop.isAdmin}
                  path={prop.path}
                  key={key}
                />
              );
            }
          })}
          {/* lap */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
