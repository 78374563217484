import {
  GET_LIST_LINK_SERVICE_OK,
  GET_LIST_LINK_SERVICE_ERR,
} from "./get-list-link-service.action";

const InitState = {
  error: null,
  content: null,
};

export default function GetListLinkServiceReducer(state = InitState, action) {
  switch (action.type) {
    case GET_LIST_LINK_SERVICE_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_LIST_LINK_SERVICE_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
