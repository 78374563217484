/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./register.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { provinceService } from "../../../redux/store/province/province.service";
import { districtService } from "../../../redux/store/district/district.action";
import { communeService } from "../../../redux/store/commune/commune.action";
import Row from "react-bootstrap/Row";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import { registerService } from "../../../redux/store/user/register/register.action";
import { Container } from "react-bootstrap";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

const initialState = {
  name: "",
  taxCode: "",
  address: "",
  phoneNumber: "",
  faxNumber: "",
  emailCompany: "",
  provinceId: 0,
  provinceObj: { id: 0, name: "" },
  districtId: 0,
  districtObj: { id: 0, name: "" },
  communeId: 0,
  communeObj: { id: 0, name: "" },
  emailUsername: "",
  password: "",
  confirmPassword: "",
  certificateNumber: "",
  disableDistrict: true,
  disableCommune: true,
  fullName: "",
  identityId: "",
  identityDate: new Date(),
  identityBy: "",
  sex: "male",
  phoneNumberUser: "",
};

class RegisterDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    // this.state = this.state = {
    //   name: "thanh@gmail.com",
    //   taxCode: "1111111111",
    //   address: "abc",
    //   phoneNumber: "0973999999",
    //   faxNumber: "0973999999",
    //   emailCompany: "com@gmail.com",
    //   provinceId: 0,
    //   provinceObj: { id: 0, name: "" },
    //   districtId: 0,
    //   districtObj: { id: 0, name: "" },
    //   communeId: 0,
    //   communeObj: { id: 0, name: "" },
    //   emailUsername: "user@gmail.com",
    //   password: "Fsoft@12345",
    //   confirmPassword: "Fsoft@12345",
    //   certificateNumber: "125352120",
    //   disableDistrict: true,
    //   disableCommune: true,
    //   fullName: "tran van thanh",

    //   identityId: "123123123",
    //   identityDate: new Date(),
    //   identityBy: "ha noi",
    //   sex: "male",
    // };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  onChangeNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.validateForm()) {
      this.props.register(this.state).then((x) => {
        if (this.props.registerState.content) {
          this.setState(initialState);
          ShowNotification(
            this.props.registerState.content.message,
            NotificationMessageType.Success
          );
          history.push(UrlCollect.StartPage);
        }
      });
    }
  };

  validateForm() {
    var item;
    if (this.state.name === "") {
      item = document.getElementById("name");
      item.focus();
      ShowNotification(
        "Tên công ty không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.taxCode === "") {
      item = document.getElementById("taxCode");
      item.focus();
      ShowNotification(
        "Mã số thuế không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }

    if (this.state.phoneNumber === "") {
      item = document.getElementById("phoneNumber");
      item.focus();
      ShowNotification(
        "Số điện thoại không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.emailCompany === "") {
      item = document.getElementById("emailCompany");
      item.focus();
      ShowNotification(
        "Email không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.emailCompany !== "") {
      const re = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
      if (!re.test(this.state.emailCompany)) {
        item = document.getElementById("emailCompany");
        item.focus();
        ShowNotification("Email không hợp lệ", NotificationMessageType.Error);
        return false;
      }
    }
    if (this.state.provinceId === 0) {
      item = document.getElementsByName("provinceId")[0];
      item.focus();
      ShowNotification(
        "Bạn chưa chọn Thành Phố/Tỉnh",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.districtId === 0) {
      item = document.getElementsByName("districtId")[0];
      item.focus();
      ShowNotification(
        "Bạn chưa chọn Quận/Huyện",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.address === "") {
      item = document.getElementById("address");
      item.focus();
      ShowNotification(
        "Địa chỉ không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.emailUsername === "") {
      item = document.getElementById("emailUsername");
      item.focus();
      ShowNotification(
        "Tên đăng nhập không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.emailUsername !== "") {
      const re = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
      if (!re.test(this.state.emailUsername)) {
        item = document.getElementById("emailUsername");
        item.focus();
        ShowNotification(
          "Tên đăng nhập không hợp lệ",
          NotificationMessageType.Error
        );
        return false;
      }
    }
    if (this.state.password === "") {
      item = document.getElementById("password");
      item.focus();
      ShowNotification(
        "Mật khẩu không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.confirmPassword === "") {
      item = document.getElementById("confirmPassword");
      item.focus();
      ShowNotification(
        "Xác nhận mật khẩu không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.confirmPassword !== this.state.password) {
      item = document.getElementById("confirmPassword");
      item.focus();
      ShowNotification(
        "Xác nhận mật khẩu không đúng",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.fullName === "") {
      item = document.getElementById("fullName");
      item.focus();
      ShowNotification(
        "Họ tên đầy đủ không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.phoneNumberUser === "") {
      item = document.getElementById("phoneNumberUser");
      item.focus();
      ShowNotification(
        "Số điện thoại không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.identityId === "") {
      item = document.getElementById("identityId");
      item.focus();
      ShowNotification(
        "Số CMND/CCCD không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (!this.state.identityDate) {
      item = document.getElementById("identityDate");
      item.focus();
      ShowNotification(
        "Ngày cấp không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.identityBy === "") {
      item = document.getElementById("identityBy");
      item.focus();
      ShowNotification(
        "Nơi cấp không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    let user_captcha_value = document.getElementById("user_captcha_input");

    if (validateCaptcha(user_captcha_value.value, false) === false) {
      user_captcha_value.focus();
      ShowNotification("Nhập mã capcha sai", NotificationMessageType.Error);
      return false;
    }
    return true;
  }

  componentDidMount() {
    loadCaptchaEnginge(5);

    var can = document.getElementById("canv");
    can.parentElement.classList.add("viewCapcha");
    var tag = document.getElementById("reload_href");
    tag.parentElement.classList.add("reload-capcha");
    tag.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise color-black" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/><path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/></svg>';

    this.props.getProvince();
  }

  backClick = (e) => {
    e.preventDefault();
    history.push(UrlCollect.StartPage);
  };

  render() {
    return (
      <section className="register">
        <Container>
          <div className="margin-body">
            <Breadcrumb className="title">Đăng kí tài khoản</Breadcrumb>
            <div className="body-content">
              <Form noValidate onSubmit={this.handleSubmit}>
                <Form.Label className="group-info">
                  Thông tin công ty:
                </Form.Label>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Tên công ty <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="name"
                      name="name"
                      onChange={this.handleChange}
                      required
                      type="text"
                      value={this.state.name}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Giấy phép hoạt động thiết kế</Form.Label>
                    <Form.Control
                      name="certificateNumber"
                      onChange={this.handleChange}
                      value={this.state.certificateNumber}
                      type="text"
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Mã số thuế <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="taxCode"
                      name="taxCode"
                      required
                      type="text"
                      value={this.state.taxCode}
                      onChange={this.handleChange}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>
                      Điện thoại <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="phoneNumber"
                      name="phoneNumber"
                      onChange={this.onChangeNumber}
                      value={this.state.phoneNumber}
                      required
                      type="text"
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Số fax</Form.Label>
                    <Form.Control
                      name="faxNumber"
                      onChange={this.handleChange}
                      type="text"
                      value={this.state.faxNumber}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>
                      Email <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="emailCompany"
                      name="emailCompany"
                      onChange={this.handleChange}
                      required
                      type="email"
                      value={this.state.emailCompany}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} className="address-register">
                    <Form.Label>
                      Thành Phố/Tỉnh<span className="requite">*</span>
                    </Form.Label>

                    <Autocomplete
                      value={this.state.provinceObj}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({ provinceId: value.id });
                          this.setState({ provinceObj: value });
                          this.setState({ disableDistrict: false });
                          this.setState({ districtId: 0 });
                          this.setState({ districtObj: { id: 0, name: "" } });
                          this.setState({ communeId: 0 });
                          this.setState({ communeObj: { id: 0, name: "" } });
                          this.props.getDistrict(value.id);
                        } else {
                          this.setState({ provinceId: 0 });
                          this.setState({ provinceObj: { id: 0, name: "" } });
                          this.setState({ districtId: 0 });
                          this.setState({ districtObj: { id: 0, name: "" } });
                          this.setState({ disableDistrict: true });
                          this.setState({ communeId: 0 });
                          this.setState({ communeObj: { id: 0, name: "" } });
                          this.setState({ disableCommune: true });
                        }
                      }}
                      options={
                        this.props.province.content
                          ? this.props.province.content
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="provinceId"
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="address-register">
                    <Form.Label>
                      Quận/Huyện <span className="requite">*</span>
                    </Form.Label>

                    <Autocomplete
                      value={this.state.districtObj}
                      disabled={this.state.disableDistrict}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({ districtId: value.id });
                          this.setState({ districtObj: value });
                          this.setState({ disableCommune: false });
                          this.setState({ communeId: 0 });
                          this.setState({ communeObj: { id: 0, name: "" } });
                          this.props.getCommune(value.id);
                        } else {
                          this.setState({ districtId: 0 });
                          this.setState({ districtObj: { id: 0, name: "" } });
                          this.setState({ communeId: 0 });
                          this.setState({ communeObj: { id: 0, name: "" } });
                          this.setState({ disableCommune: true });
                        }
                      }}
                      options={
                        this.props.district.content
                          ? this.props.district.content
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="districtId"
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="address-register">
                    <Form.Label>Phường/Xã</Form.Label>
                    <Autocomplete
                      value={this.state.communeObj}
                      disabled={this.state.disableCommune}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({ communeId: value.id });
                        }
                      }}
                      options={
                        this.props.commune.content
                          ? this.props.commune.content
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </Form.Group>
                </Form.Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Địa chỉ <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="address"
                      name="address"
                      onChange={this.handleChange}
                      value={this.state.address}
                      required
                      type="text"
                    />
                  </Form.Group>
                </Row>

                <Form.Label className="group-info">
                  Thông tin tài khoản:
                </Form.Label>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Tên đăng nhập <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="emailUsername"
                      name="emailUsername"
                      onChange={this.handleChange}
                      value={this.state.emailUsername}
                      required
                      type="email"
                      placeholder="email@mail.com"
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>
                      Mật khẩu <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="password"
                      name="password"
                      onChange={this.handleChange}
                      value={this.state.password}
                      required
                      type="password"
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>
                      Lại mật khẩu <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={this.handleChange}
                      value={this.state.confirmPassword}
                      required
                      type="password"
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Họ tên đầy đủ <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="fullName"
                      name="fullName"
                      onChange={this.handleChange}
                      value={this.state.fullName}
                      required
                      type="text"
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Giới tính <span className="requite">*</span>
                    </Form.Label>
                    <div>
                      <Form.Check
                        name="sex"
                        checked={this.state.sex === "male"}
                        onChange={this.handleChange}
                        inline
                        label="Nam"
                        type="radio"
                        value="male"
                      />
                      <Form.Check
                        name="sex"
                        checked={this.state.sex === "female"}
                        onChange={this.handleChange}
                        inline
                        label="Nữ"
                        type="radio"
                        value="female"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Số điện thoại <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="phoneNumberUser"
                      name="phoneNumberUser"
                      onChange={this.onChangeNumber}
                      value={this.state.phoneNumberUser}
                      required
                      type="text"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Số CMND/CCCD <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="identityId"
                      name="identityId"
                      onChange={this.onChangeNumber}
                      value={this.state.identityId}
                      required
                      type="text"
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="identityBy">
                    <Form.Label>
                      Ngày cấp <span className="requite">*</span>
                    </Form.Label>
                    <div>
                      <DatePicker
                        id="identityDate"
                        name="identityDate"
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.identityDate}
                        onChange={(date) =>
                          this.setState({ identityDate: date })
                        }
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Nơi cấp <span className="requite">*</span>
                    </Form.Label>
                    <Form.Control
                      id="identityBy"
                      name="identityBy"
                      onChange={this.handleChange}
                      value={this.state.identityBy}
                      required
                      type="text"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Label className="group-info">Mã xác nhận:</Form.Label>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Control
                      id="user_captcha_input"
                      name="user_captcha_input"
                      required
                      type="text"
                      placeholder="Nhập mã xác nhận"
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <LoadCanvasTemplate />
                  </Form.Group>
                  <Form.Group as={Col}></Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <div className="center">
                      <Button
                        className="button-back"
                        variant="primary"
                        onClick={this.backClick}
                      >
                        QUAY LẠI
                      </Button>
                      <span className="margin-5"></span>
                      <Button
                        className="button-register"
                        variant="primary"
                        type="submit"
                        // onClick={this.registerClick}
                      >
                        ĐĂNG KÝ
                      </Button>
                    </div>
                  </Form.Group>
                </Form.Row>
              </Form>
            </div>
          </div>
        </Container>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  province: state.provinceUser,
  district: state.district,
  commune: state.commune,
  registerState: state.register,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // login: userService.login,
      getProvince: provinceService.getProvince,
      getDistrict: districtService.getDistrict,
      getCommune: communeService.getCommune,
      register: registerService.register,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(RegisterDesktop);
