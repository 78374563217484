import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const GET_BY_ID_OK = "GET_BY_ID_OK";
export const GET_BY_ID_ERR = "GET_BY_ID_ERR";

export function getByIdOk(payload) {
  return {
    type: GET_BY_ID_OK,
    payload,
  };
}

export function getByIdErr(payload) {
  return {
    type: GET_BY_ID_ERR,
    payload,
  };
}

export const getByIdService = {
  getById,
};

function getById(params) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.CMSGetById + params)
      .then((response) => {
        dispatch(getByIdOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getByIdErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
