const UrlCollect = {
  StartPage: "/",
  Home: "/Home",
  Index: "/trang-chu",
  SpecializedPlanning: "/quy-hoach-chuyen-nganh",
  SyntheticPlanning: "/tong-hop-quy-hoach",
  GeneralPlanning: "/quy-hoach-chung",
  SubdivisionPlanning: "/quy-hoach-phan-khu",
  DetailedPlanning: "/quy-hoach-chi-tiet",
  PlanningAnnouncement: "/cong-bo-quy-hoach",
  //News: "/news",
  RecordsManager: "/quan-ly-ho-so",
  Contact: "/lien-he",
  PlanningMapViewDetail: "/ban-do-quy-hoach/",
  PlanningMap: "/ban-do-quy-hoach",

  //-- User
  Login: "/login",
  HomeUser: "/user/home",
  Register: "/register",
  ForgotPassword: "/forgot-password",
  ConfirmCode: "/confirm-code",
  ResetPassword: "/reset-password",
  PlanningDesign: "/appraisal-and-approval",
  ListFiles: "/list-files",
  FileDetail: "/file-detail",
  FileResult: "/file-result",
  ViewUser: "/view-user",
  ChangePassword: "/change-password",
  ListQualify: "/list-qualify",
  QualifyDetail: "/qualify-detail",
  ListHelping: "/list-helping",
  Helping: "/helping",
  UserAdmin: "/user-admin/",
  ListNotification: "/list-notification",
  NotificationDetail: "/notification-detail",

  //-- Xin y kien cong dong
  PleaseConsultTheCommunity: "/y-kien-cong-dong",
  PleaseComment: "/xin-y-kien",
  SummaryIdeas: "/y-kien-tong-hop",

  //-- Tin tuc
  NewsList: "/tin-tuc",
  NewsDetail: "/tin-tuc-chi-tiet",

  //-- Error
  Page500: "/page-500/",
  Page404: "/page-404",

  //-- Admin
  //ProcessBoardPlanning: "/quy-trinh-cong-bo-quy-hoach",
  DashboardAdmin: "/dashboard-admin",
  DocumentNew: "/doc-new",
  DocumentNewDetail: "/doc-new-detail",
  AdmLogin: "/adm/login",
  Weblink: "/weblink",
  Province: "/province",
  District: "/district",
  Commune: "/commune",
  Project: "/project",
  Department: "/department",
  Position: "/position",
  News: "/news",
};

export default UrlCollect;
