import React from "react";
import "./footer.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getListLinkServiceAction } from "../../redux/store/user/administrative/get-list-link-service/get-list-link-service.action";
import Form from "react-bootstrap/Form";
// import Vilanguage from "../../languages/vi";

// const LanguageDisplay = Vilanguage;

class FooterView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      linkService: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const linkService = JSON.parse(sessionStorage.getItem("linkService"));
    if (!linkService) {
      this.props.getListLinkService().then((x) => {
        if (this.props.getListLinkServiceState.content) {
          sessionStorage.setItem(
            "linkService",
            JSON.stringify(this.props.getListLinkServiceState.content.items)
          );
          this.setState({
            linkService: JSON.parse(sessionStorage.getItem("linkService")),
          });
        }
      });
    } else {
      this.setState({ linkService: linkService });
    }
    // this.getAllPlanningType();
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });

    if (event.target.value !== "") {
      window.open(event.target.value, "_blank");
    }
  }

  render() {
    return (
      <footer>
        <div className="info">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="name">
                  <img
                    alt=""
                    src={require("../../assets/images/logo-footer.png")}
                  />
                  <h3>
                    DỊCH VỤ CÔNG VỀ <br />
                    THẨM DUYỆT THIẾT KẾ
                    <br />
                    DỰ ÁN ĐẦU TƯ XÂY DỰNG
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="detail">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-geo-alt-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                    </svg>
                    Địa chỉ: Tầng 1, nhà E, Khu Vinaconex 1, số 289A Khuất Duy Tiến, Quận Cầu Giấy, TP. Hà Nội
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-telephone-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      />
                    </svg>
                    Điện thoại: (84) 976494989 - Fax: (84 4) 73006899
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-envelope-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                    </svg>
                    Email: acud@acudgroup.vn
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <form>
                  <div className="form-group related-website">
                    <label htmlFor="exampleFormControlSelect1">
                      Liên kết website
                    </label>
                    <Form.Control
                      className="form-control"
                      id="exampleFormControlSelect1"
                      name="link"
                      as="select"
                      value={this.state.link}
                      onChange={this.handleChange}
                    >
                      <option value="">--Liên kết website--</option>
                      {this.state.linkService
                        ? this.state.linkService.map((obj, index) => (
                            <option key={index} value={obj.link}>
                              {obj.name}
                            </option>
                          ))
                        : null}
                    </Form.Control>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            © Bản quyền thuộc về CÔNG TY CỔ PHẦN CÔNG NGHỆ CAO CTECH
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  getListLinkServiceState: state.getListLinkService,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getListLinkService: getListLinkServiceAction.getListLinkService,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FooterView);
